//import { AES_GCM, Pbkdf2HmacSha256 } from 'asmcrypto.js/dist_es5/entry-export_all';
import { AES_GCM, Pbkdf2HmacSha256 } from 'asmcrypto.js';
import { dbPut, dbGet } from '@p4b/utils-db';
import "zip-js"
//import { Reader, Writer } from '@zip.js/zip.js'


//----------------------------------------------------------------------------
// Stream Reader & Writer for IndexedDB

export class IDBWriter extends zip.Writer {
    private buf: Uint8Array;
    private ptrChunk: number;
    private ptrOffset: number;
    private store: string;
    private dbChunkSize: number;

    public constructor(store: string, dbChunkSize: number) {
        super();
        this.store = store;
        this.dbChunkSize = dbChunkSize;
        this.buf = new Uint8Array(dbChunkSize);
        this.ptrChunk = 0;
        this.ptrOffset = 0;
    }

    public init(callback: () => void, onerror: (err: unknown) => void): void {
        try {
            callback();
        } catch(err) {
            console.error(err);
            onerror(err);
        }
    }

    public getPtr(): number {
        return this.ptrChunk * this.dbChunkSize + this.ptrOffset;
    }

    public async writeUint8ArrayPromise(data: Uint8Array): Promise<void> {
        if (this.ptrOffset + data.length < this.buf.length) {
            this.buf.set(data, this.ptrOffset);
            this.ptrOffset += data.length;
        } else if (this.ptrOffset + data.length < 2 * this.buf.length) {
            const split = this.buf.length - this.ptrOffset;
            this.buf.set(data.subarray(0, split), this.ptrOffset);
            await dbPut(this.store, this.ptrChunk++, this.buf.buffer);
            this.buf.set(data.subarray(split), 0);
            this.ptrOffset = data.length - split;
        } else {
            let offset = this.buf.length - this.ptrOffset;
            this.buf.set(data.subarray(0, offset), this.ptrOffset);
            await dbPut(this.store, this.ptrChunk++, this.buf.buffer);
            while (offset + this.buf.length < data.length) {
                this.buf.set(data.subarray(offset, offset + this.buf.length), 0);
                await dbPut(this.store, this.ptrChunk++, this.buf.buffer);
                offset += this.buf.length;
            }
            this.buf.set(data.subarray(offset), 0);
            this.ptrOffset = data.length - offset;
        }
    }

    public writeUint8Array(data: Uint8Array, callback: () => void, onerror: (err: unknown) => void): void {
        (async () => {
            await this.writeUint8ArrayPromise(data)
            callback();
        })().catch(err => {
            console.error(err);
            onerror(err);
        });
    }

    public async closePromise(): Promise<void> {
        if (this.ptrOffset > 0) {
            await dbPut(this.store, this.ptrChunk++, this.buf.buffer);
            this.ptrOffset = 0;
        }
    }
}

export class IDBReader extends zip.Reader {
    private cacheId?: number;
    private cacheData?: ArrayBuffer;
    private store: string;
    private dbChunkSize: number

    public constructor(store: string, dbChunkSize: number) {
        super();
        this.store = store;
        this.dbChunkSize = dbChunkSize;
    }

    private async readBuf(chunkId: number): Promise<ArrayBuffer> {
        if (this.cacheData && chunkId === this.cacheId) {
            return this.cacheData;
        }
        const data = await dbGet<ArrayBuffer>(this.store, chunkId);
        if (data == undefined) {
            throw 'Image store key does not exist: ' + chunkId;
        }
        this.cacheData = data;
        this.cacheId = chunkId;
        return data;
    }

    public async readUint8ArrayPromise(start: number, end: number): Promise<Uint8Array> {
        const startChunk = Math.floor(start / this.dbChunkSize);
        const startOffset = start - (startChunk * this.dbChunkSize);
        const endChunk = Math.floor(end / this.dbChunkSize);
        const endOffset = end - (endChunk * this.dbChunkSize);
        const length = end - start;
        //log('IDBReader readUint8Array: start chunk:', startChunk, 'offset:', startOffset, ' ->  end chunk:', endChunk, 'offset:', endOffset);

        if (startChunk === endChunk) {
            const buf1 = (await this.readBuf(startChunk)).slice(startOffset, endOffset);
            return new Uint8Array(buf1);
        } else if (startChunk + 1 === endChunk) {
            const data = new Uint8Array(length);
            const buf1 = await this.readBuf(startChunk);
            data.set(new Uint8Array(buf1).subarray(startOffset));
            const buf2 = await this.readBuf(endChunk);
            data.set(new Uint8Array(buf2).subarray(0, endOffset), this.dbChunkSize - startOffset);
            return data;
        } else {
            const data = new Uint8Array(length);
            const buf1 = await this.readBuf(startChunk);
            data.set(new Uint8Array(buf1).subarray(startOffset));
            let offset = this.dbChunkSize - startOffset;
            for (let chunk = startChunk + 1; chunk < endChunk; ++chunk) {
                const buf2 = await this.readBuf(chunk);
                data.set(new Uint8Array(buf2), offset);
                offset += this.dbChunkSize;
            }
            const buf3 = await this.readBuf(endChunk);
            data.set(new Uint8Array(buf3).subarray(0, endOffset), offset);
            return data;
        }
    }

    public close(): void {
        this.cacheId = undefined;
        this.cacheData = undefined;
    }
}


//----------------------------------------------------------------------------
// Read Encrypted Stream from IndexedDB

// WebCrypto

async function webcryptoDeriveKey(subtle: SubtleCrypto, pin: string, salt: Uint8Array, iterations: number): Promise<CryptoKey> {
    const textEncoder = new TextEncoder();
    const material = await subtle.importKey('raw', textEncoder.encode(pin), 'PBKDF2', false, ['deriveKey']);
    const key = await subtle.deriveKey(
        {
            name: 'PBKDF2',
            salt, // : textEncoder.encode('Practique'),
            iterations, // : 1009,
            hash: 'SHA-256'
        },
        material,
        {
            name: 'AES-GCM',
            length: 256
        },
        true,
        ['decrypt']
    );
    return key;
}

async function webcryptoImportKey(subtle: SubtleCrypto, key: ArrayBuffer): Promise<CryptoKey> {
    return subtle.importKey('raw', key, {name: 'AES-GCM'}, false, ['decrypt']) as Promise<CryptoKey>;
}

async function webcryptoDecryptChunk(
    subtle: SubtleCrypto,
    aesKey: CryptoKey,
    aesIv: Uint8Array,
    ciphertext: Uint8Array
): Promise<ArrayBuffer> {
    const plaintext = await subtle.decrypt({ 'name': 'AES-GCM', 'iv': aesIv }, aesKey, ciphertext);
    return plaintext;
}

// AsmCrypto

function asmcryptoDeriveKey(pin: string): ArrayBuffer {
    const textEncoder = new TextEncoder();
    return Pbkdf2HmacSha256(textEncoder.encode(pin), textEncoder.encode('Practique'), 1009, 32).buffer;
}

function asmcryptoDecryptChunk(
    aesKey: ArrayBuffer,
    aesIv: Uint8Array,
    ciphertext: Uint8Array
): ArrayBuffer {
    return AES_GCM.decrypt(ciphertext, new Uint8Array(aesKey), aesIv, undefined, 16).buffer;
}

declare global {
    interface Crypto {
        webkitSubtle: typeof crypto.subtle;
    }
}

class Decryptor {
    private webcryptoAesKey?: CryptoKey;
    private asmcryptoAesKey?: ArrayBuffer;
    private subtle?: SubtleCrypto;
    private pin: string;

    constructor(pin: string) {
        this.subtle = crypto.subtle || crypto.webkitSubtle;
        this.pin = pin;
    }

    async decryptChunk(buf: ArrayBuffer): Promise<ArrayBuffer> {
        const aesIv = new Uint8Array(buf.slice(0, 12))
            , ciphertext = new Uint8Array(buf.slice(12, buf.byteLength))
            ;

        if (this.subtle && this.webcryptoAesKey) {
            return await webcryptoDecryptChunk(this.subtle, this.webcryptoAesKey, aesIv, ciphertext);
        } else if (this.asmcryptoAesKey) {
            return asmcryptoDecryptChunk(this.asmcryptoAesKey, aesIv, ciphertext);
        } else if (this.subtle) {
            try {
                console.log('TRY NEW WEBCRYPTO DERIVE KEY');
                const webcryptoAesKey = await webcryptoDeriveKey(this.subtle, this.pin, aesIv, 1000000);
                try {
                    console.log('TRY WEBCRYPTO DECRYPT');
                    const data = await webcryptoDecryptChunk(this.subtle, webcryptoAesKey, aesIv, ciphertext);
                    this.webcryptoAesKey = webcryptoAesKey;
                    return data
                } catch (err) {
                    console.log(String(err), "\nTRY OLD WEBCRYPTO KEY IMPORT");
                    const webcryptoAesKey = await webcryptoDeriveKey(this.subtle, this.pin, new TextEncoder().encode('Practique'), 1009);
                    console.log('TRY WEBCRYPTO DECRYPT');
                    const data = await webcryptoDecryptChunk(this.subtle, webcryptoAesKey, aesIv, ciphertext);
                    this.webcryptoAesKey = webcryptoAesKey;
                    return data;
                }
            } catch (e1) {
                console.log(String(e1), "\nTRY ASMCRYPTO KEY IMPORT")
                const asmcryptoAesKey = asmcryptoDeriveKey(this.pin);
                try {
                    console.log('TRY WEBCRYPTO DECRYPT');
                    const webcryptoAesKey = await webcryptoImportKey(this.subtle, asmcryptoAesKey);
                    const data = await webcryptoDecryptChunk(this.subtle, webcryptoAesKey, aesIv, ciphertext);
                    this.webcryptoAesKey = webcryptoAesKey;
                    return data;
                } catch (e2) {
                    console.log(String(e2), "\nASMCRYPTO  DECRYPT");
                    const data = asmcryptoDecryptChunk(asmcryptoAesKey, aesIv, ciphertext);
                    this.asmcryptoAesKey = asmcryptoAesKey;
                    return data;
                }
            }
        } else {
            console.log('ASMCRYPTO');
            const asmcryptoAesKey = asmcryptoDeriveKey(this.pin);
            const data = asmcryptoDecryptChunk(asmcryptoAesKey, aesIv, ciphertext);
            this.asmcryptoAesKey = asmcryptoAesKey;
            return data;
        }
    }
}

export type ExamId = string;

export class IndexedDBReader extends zip.Reader {
    private decryptor: Decryptor;
    private cacheId: number | null;
    private cacheValue: ArrayBuffer | null;
    private store: string;
    private examId: ExamId;
    private dbChunkSize: number;
    public readonly size: number; // zip.js needs to read this field
    private err: unknown = '';

    get lastError() {
        return this.err;
    }

    public constructor(
        store: string,
        key: string,
        examId: ExamId,
        dbChunkSize: number,
        size: number
    ) {
        super();
        this.decryptor = new Decryptor(key);
        this.store = store;
        this.examId = examId;
        this.dbChunkSize = dbChunkSize;
        this.size = size;
        this.cacheId = null;
        this.cacheValue = null;
        console.log('CONSTRUCT: IndexedDBReader', store, key, examId, dbChunkSize, size);
    }

    public init(callback: () => void, onerror: (err: unknown) => void): void {
        try {
            console.log('init: IndexedDBReader', this.dbChunkSize);
            this.cacheId = null;
            this.cacheValue = null;
            callback()
        } catch (err) {
            this.err = err;
            onerror(err);
        }
    }

    private async getChunk(chunkId: number): Promise<ArrayBuffer> {
        if (chunkId == this.cacheId && this.cacheValue != null) {
            return this.cacheValue;
        } else {
            this.cacheId = null;
            this.cacheValue = null;
            const ciphertext = await dbGet<ArrayBuffer>(this.store, [this.examId, chunkId]);
            if (!ciphertext) {
                throw 'IndexedDBReader: getChunk ' + chunkId + ' null';
            }
            const plaintext = await this.decryptor.decryptChunk(ciphertext);
            this.cacheId = chunkId;
            this.cacheValue = plaintext;
            //console.log('GOT CHUNK');
            return plaintext;
        }
    }

    public readUint8Array(
        start: number,
        length: number,
        callback: (buf: Uint8Array) => void,
        onerror: (err: string) => void
    ): void {
        //console.log(start, length, this.dbChunkSize);
        (async () => {
            const startChunk = Math.floor(start / this.dbChunkSize);
            const startOffset = start - (startChunk * this.dbChunkSize);
            const end = start + length;
            const endChunk = Math.floor(end / this.dbChunkSize);
            const endOffset = end - (endChunk * this.dbChunkSize);

            //console.log('IndexedDBReader readUint8Array: start chunk:', startChunk, 'offset:', startOffset, ' ->  end chunk:', endChunk, 'offset:', endOffset);
            if (startChunk === endChunk) {
                // optiminse single chunk
                const buf = await this.getChunk(startChunk);
                callback(new Uint8Array(buf.slice(startOffset, endOffset)));
            } else if (startChunk + 1 === endChunk) {
                // optimise spanning two chunks
                const buf = new Uint8Array(length);
                const buf1 = await this.getChunk(startChunk);
                buf.set(new Uint8Array(buf1).subarray(startOffset));
                const buf2 = await this.getChunk(endChunk);
                buf.set(new Uint8Array(buf2).subarray(0, endOffset), this.dbChunkSize - startOffset);
                callback(buf);
            } else {
                const buf = new Uint8Array(length);
                const buf1 = await this.getChunk(startChunk);
                buf.set(new Uint8Array(buf1).subarray(startOffset));
                let offset = this.dbChunkSize - startOffset;
                for (let chunk = startChunk + 1; chunk < endChunk; ++chunk) {
                    const buf2 = await this.getChunk(chunk);
                    buf.set(new Uint8Array(buf2), offset);
                    offset += this.dbChunkSize;
                }
                const buf3 = await this.getChunk(endChunk);
                buf.set(new Uint8Array(buf3).subarray(0, endOffset), offset);
                callback(buf);
            }
        })().catch(err => {
            this.err = `[readUint8Array] ${err}`;
            onerror(err);
        });
    }

    public close(): void {
        this.cacheId = null;
        this.cacheValue = null;
    }
}
