import { DOMcreateElement, Icon} from '@p4b/utils-jsx';
import { faCalculator } from '@fortawesome/free-solid-svg-icons';
import { ControlPanel } from '@p4b/question-base';
import { translate } from '@p4b/utils-lang';
import { evaluate } from 'mathjs';
import { newUniqueName } from '@p4b/utils';
import { configInfoToolTab, configInfoToolText, configInfoToolButton } from './exam-accessibility';

const passive: AddEventListenerOptions | EventListenerOptions = {passive: true};

export class Calculator {
    private controlPanel: ControlPanel;
    private button: HTMLButtonElement;
    private panel: HTMLDivElement;
    private input: HTMLInputElement;
    private equals: HTMLButtonElement;

    public constructor(
        controlPanel: ControlPanel,
    ) {
        this.controlPanel = controlPanel;
        this.button = <button id="calc-button" className={configInfoToolTab}><Icon icon={faCalculator}/><span className="app-button-text">{translate('CONTROL_CALCULATOR')}</span></button>;
        this.input = <input type="text" size={20} className={"calc-text " + configInfoToolText} autocomplete={newUniqueName()} autocorrect="off" autocapitalize="off" spellcheck="false"/>;
        this.equals = <input type="button" value="=" className={"calc-button nav-pad-left " + configInfoToolButton}/>;
        this.panel = <div className="calculator-bar" hidden={true}>{this.input}{this.equals}</div>;
        this.controlPanel.panel().appendChild(this.panel);
        this.controlPanel.add(this.button);
        this.button.addEventListener('click', this.handleClick);
        this.equals.addEventListener('click', this.handleEq, passive);
        this.input.addEventListener('keyup', this.handleText, passive);
    }

    private readonly handleClick = () => {
        this.panel.hidden = !this.panel.hidden;
        this.button.setAttribute('aria-pressed', String(!this.panel.hidden));
    }

    private handleEq = (): void => {
        try {
            this.input.value = evaluate(this.input.value || '0');
        } catch(err) {
            console.warn(err);
        }
    }

    private handleText = (event: KeyboardEvent): void => {
        if (event.key === 'Enter') {
            try {
                this.input.value = evaluate(this.input.value || '0');
            } catch(err) {
                console.warn(err);
            }
        }
    }

    public disabled(disabled: boolean) {
        this.button.disabled = disabled;
        if (disabled) {
            this.panel.hidden = true;
        }
    }

    public destroy() {
        this.controlPanel.remove(this.button);
        this.controlPanel.panel().removeChild(this.panel);
        this.button.removeEventListener('click', this.handleClick);
        this.equals.removeEventListener('click', this.handleEq, passive);
        this.input.removeEventListener('keyup', this.handleText, passive);
    }
}
