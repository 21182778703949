import {mkNode, removeNode} from '@p4b/utils';

export class Progress {
    public onProgress: (progress: number) => Promise<void> = async () => undefined;
    private progressBox: HTMLDivElement;
    private title: HTMLDivElement;
    private subtext: HTMLDivElement;
    private text: Text;
    private totalSize: number;
    private currentSize: number;

    constructor(parent: HTMLElement) {
        this.progressBox = mkNode('div', {className: 'progress-panel'});
        this.title = mkNode('div', {parent: this.progressBox, className: 'progress-title'});
        this.subtext = mkNode('div', {parent: this.progressBox, className: 'progress-subtext'});
        //mkNode('div', {parent: this.progressBox, className: 'spinner'});
        const textBox = mkNode('div', {parent: this.progressBox, className: 'progress-text'});
        this.text = mkNode('text', {parent: textBox});
        this.totalSize = 0;
        this.currentSize = 0;
        parent.appendChild(this.progressBox);
    }

    destroy(): void {
        removeNode(this.progressBox);
    }

    setTitle(title: string): void {
        this.title.innerHTML = title;
    }

    setDescription(description: string): void {
        this.subtext.innerHTML = description;
    }

    setTotalSize(size: number): void {
        this.totalSize = size;
        this.text.nodeValue = '0%';
    }

    async setProgress(progress: number): Promise<void> {
        const p = Math.floor(100.0 * (this.currentSize + progress) / this.totalSize);
        this.text.nodeValue = p + '%';
        await this.onProgress(progress);
    }

    addOffset(progress: number): void {
        this.currentSize += progress;
    }

}
