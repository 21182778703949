import { crc32, UInt64, PCG32, shuffle, mkNode, safeJsonParse, getFreeSpace, isIndexed } from '@p4b/utils';
import { dbGet, dbPut, dbCursor, dbDelete, StorageError, dbPutList, openDatabase, toStorageError } from '@p4b/utils-db';
import { IndexedDBReader, IDBReader, IDBWriter, ExamId } from '@p4b/utils-zip';
import { HttpError, /*postJson,*/ getText, getArrayBuffer, /*, examsApi*/
rangeSupported, getJson, requestJson } from '@p4b/utils-net';
import { Frame, Img, IType } from '@p4b/image-base';
import { makeStd, StdRenderer } from '@p4b/Renderers/render-std';
import { makeTiff, tiffDetect, TiffRenderer } from '@p4b/Renderers/render-tiff';
import { makePdf, PdfRenderer } from '@p4b/Renderers/render-pdf';
import { makeDicom, DicomRenderer, DicomImg, addDicom, DicomFrame } from '@p4b/Renderers/render-dicom';
import { QuestionManifest, AnswerResources, AnswerValue } from '@p4b/question-base';
import { Progress } from '@p4b/utils-progress';
import { alertModal } from "@p4b/modal-dialog";
//import { ExamAdmin, ExamItem, ExamAdminOrList } from '@p4b/p4b-api';
import examValidate from '@gen/validate_exam';
import { j2kDetect, J2kRenderer, makeJ2k } from '@p4b/Renderers/render-jpeg2k';
import { translate } from '@p4b/utils-lang';
import { isSchedule } from '@p4b/exam-timer';
import { ExamState, isState } from '@p4b/exam-viewer';
import { ImageData } from 'canvas';
//import { Writer, ZipReader, TextWriter, Entry } from '@zip.js/zip.js';
import 'zip-js';

zip.useWebWorkers = false;

export type ExamMap = Map<string, ExamItem>;
/*
interface ExamMeta {
    answer_aes_key?: string;
    randomize?: boolean;
    demo?: boolean;
    show_question_title?: boolean;
    enableCopyPaste?: boolean;
    disableResourceLocking?: boolean;
}
*/

export interface RemoteExamItem {
    id: string;
    component: number;
    version: number;
    title: string;
    full_title: string;
    link: string;
    proctored?: boolean;
    demo?: boolean;
    pin?: string;
    state?: string;
    autostart?: boolean;
}

export interface ExamItem extends RemoteExamItem {
    size: number;
    ranged: boolean;
}

export interface FetchExamItem extends RemoteExamItem {
    size?: number;
    ranged?: boolean;
}

export interface Structure {
    backendQid: number[];
    backendAid: number[];
    answerType: string[];
    indents?: number[];
    displayQstNumber: string;
    displayAnsNumber: (string|undefined)[];
    length: number;
    visible: (Expr | undefined)[];
    question: number;
    factor?: string;
    interview?: number;
    round?: number;
    circuit?: number;
    room?: number;
    case?: number;
    ofCases?: number;
    mandatory?: boolean[];
}

export interface State {
    candidateId: string;
    candidatePin?: string;
    badPin?: string;
    examPin?: string;
    autoPin?: boolean;
    chosenExamId?: ExamId;
    //exams: ExamMap;
    //structure: Structure[]; // (HTMLElement[] | Structure)[];
    //admin?: boolean;
    //qid?: number;
    //aid?: number;
    //downloaded?: boolean;
    //autologin?: boolean;
}

//----------------------------------------------------------------------------
// Assigment Data Model

export interface ExamAdmin {
    admin?: boolean;
    exams: RemoteExamItem[];
}


// eslint-disable-next-line @typescript-eslint/no-explicit-any
function isExamAdmin(x: any): x is ExamAdmin {
    return (x as ExamAdmin).exams != undefined;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function isExamIndex(x: any): x is RemoteExamItem[] {
    return (x as ExamItem[]).length != undefined
}

export async function getExamList(): Promise<{admin: boolean; exams?: RemoteExamItem[]}> {
    const response = await getJson('/app/available_exams.json');
    if (isExamAdmin(response)) {
        return {admin: response.admin === true, exams: response.exams};
    } else if (isExamIndex(response)) {
        return {admin: false, exams: response};
    } else {
        return {admin: false};
    }
}

export interface ProctorExamity {
    redirectUrl: string;
    UserName?: string;
}

export interface Proctors {
    examity: ProctorExamity;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function isExamity(x: unknown): x is Proctors {
    return isIndexed(x) &&
        isIndexed(x.examity) && typeof x.examity.redirectUrl === 'string' &&
        (typeof x.examity.UserName === 'string' || typeof x.examity.UserName === 'undefined');
}

export interface GenericProctor {
    proctorName: string;
    redirectUrl: string;
}

export function isGenericProctor(x: unknown): x is GenericProctor {
    return isIndexed(x) && typeof x.proctorName === 'string' && typeof x.redirectUrl === 'string'
}

export async function getProctors(): Promise<unknown> {
    try {
        return await getJson('/app/proctors/');
    } catch (err) {
        if (err instanceof Error) {
            console.error(err.message);
        }
        return {};
    }
}

export function proctorExamity(config: ProctorExamity): void {
    console.debug('EXAMITY', config);
    const {redirectUrl, UserName} = config;
    if (UserName) {
        const form = mkNode('form', {attrib: {method: 'post', action: redirectUrl}});
        mkNode('input', {parent: form, attrib: {type: 'hidden', name: 'UserName', value: UserName}});
        document.body.appendChild(form);
        form.submit();
    } else {
        window.location.href = redirectUrl;
    }
}

//----------------------------------------------------------------------------
// Encrypted Exam Data Model

function isExam(x: unknown): x is PractiqueNet.ExamJson {
    return examValidate(x);
}

function isImageSet(x: PractiqueNet.ExamJson.Definitions.Image): x is PractiqueNet.ExamJson.Definitions.ImageSet {
    return (x as PractiqueNet.ExamJson.Definitions.ImageSet).set != undefined;
}

function isImageSingle(x: PractiqueNet.ExamJson.Definitions.Image): x is PractiqueNet.ExamJson.Definitions.ImageSingle {
    return (x as PractiqueNet.ExamJson.Definitions.ImageSingle).image != undefined;
}

function isResource(x: PractiqueNet.ExamJson.Definitions.Image): x is PractiqueNet.ExamJson.Definitions.Resource {
    return (x as PractiqueNet.ExamJson.Definitions.Resource).type != undefined;
}

export async function deleteExam(exams: ExamMap, examId: ExamId | undefined, notifyServer = false): Promise<void> {
    if (examId == null) {
        throw 'invalid exam id';
    }
    await dbDelete('exams', examId);
    await dbDelete('encrypted', IDBKeyRange.bound(
        [examId, 0],
        [examId, Number.MAX_SAFE_INTEGER]
    ));
    if (notifyServer) {
        try {
            await getText(`/app/${exams.get(examId)?.id}/deleted/`);
        } catch (err) {
            console.error('DELETE_EXAM', String(err));
        }
    }
    exams.delete(examId);
}

export async function updateExamPins(localExams: ExamMap, remoteExams: RemoteExamItem[]): Promise<void> {
    for (const remoteExam of remoteExams) {
        const key = [remoteExam.id, remoteExam.component].join('-');
        const localExam = localExams.get(key); //await dbGet<ExamItem>('exams', key);
        if (localExam) {
            const updated = {...localExam, pin: remoteExam.pin};
            console.debug('UPDATE_EXAM_PIN', key, updated);
            await dbPut('exams', key, updated);
            localExams.set(key, updated);
        }
    }
}

export async function getLocalExams(): Promise<ExamMap> {
    const exams = new Map<ExamId, ExamItem>();
    await dbCursor('exams', undefined, (cursor): void => {
        exams.set(cursor.key as ExamId, cursor.value);
    });
    return exams;
}

export async function deleteUnavailable(localExams: ExamMap, remoteExams: RemoteExamItem[]): Promise<void> {
    const deleteSet = new Set(localExams.keys());
    for (const exam of remoteExams) {
        deleteSet.delete([exam.id, exam.component].join('-'));
    }
    for (const examId of Array.from(deleteSet.values())) {
        await deleteExam(localExams, examId);
        localExams.delete(examId);
    }
}

export function getFetchList(localExams: ExamMap, remoteExams: RemoteExamItem[]): FetchExamItem[] {
    console.debug('EXAM_LIST_LOCAL', Array.from(localExams.values()));
    console.debug('EXAM_LIST_REMOTE', remoteExams);
    const fetchList: FetchExamItem[] = [];
    for (const remote of remoteExams.values()) {
        const key = [remote.id, remote.component].join('-');
        const local = localExams.get(key);
        if (!local || local.version < remote.version) {
            fetchList.push(remote);
        }
    }
    console.debug('FETCH_LIST', fetchList);
    return fetchList;
}

async function getExamSizes(fetchList: FetchExamItem[]): Promise<{sizes: Map<string, {ranged: boolean, size: number}>, failCount: number, alerts: string}> {
    const sizes = new Map<string, {ranged: boolean, size: number}>();
    let failCount = 0;
    let alerts = '';
    for (const exam of fetchList) {
        if (exam.size !== undefined && exam.ranged !== undefined) {
            console.debug('LOCAL SIZE & RANGE');
            sizes.set(exam.link, {ranged: exam.ranged, size: exam.size});
        } else {
            try {
                sizes.set(exam.link, await rangeSupported(exam.link));
            } catch (err) {
                if (err instanceof HttpError && err.status == 412) {
                    console.warn(`Download count exceeded: ${exam.title}\n`);
                    alerts += translate('DOWNLOAD_COUNT_EXCEEDED', {examTitle: exam.title});
                } else {
                    console.error(exam.title, String(err));
                    alerts += `${exam.title}: ${String(err)}`;
                }
                ++failCount;
            }
        }
    }
    return {sizes, failCount, alerts};
}

async function storeExamChunks(examId: ExamId, pos: number, chunks: ArrayBuffer): Promise<void> {
    const size = chunks.byteLength;
    const chunkSize = 0x100001c;
    const count = Math.ceil(size / chunkSize);
    let start = 0;
    for (let i = pos; i < pos + count; ++i) {
        const end = (size - start > chunkSize) ? (start + chunkSize) : size;
        console.debug('PUT ENCRYPTED', [examId, i]);
        await dbPut('encrypted', [examId, i], chunks.slice(start, end));
        console.debug ('ENCRYPTED DONE');
        start = end;
    }
}

async function fetchExam(id: ExamId, url: string, {ranged, size}: {ranged: boolean, size: number}, progress: Progress): Promise<{downloaded: number, checked: number}> {
    console.debug('RANGED', ranged, size);

    if (ranged) {
        let downloaded = 0, checked = 0;
        const chunkSize = 0x100001c;
        const chunks = Math.ceil(size / chunkSize);
        const lastChunk = (size === chunks * chunkSize) ? chunkSize : (size - (chunks - 1) * chunkSize);

        for (let n = 0; n < chunks; ++n) {
            const buf = await dbGet<ArrayBuffer>('encrypted', [id, n]);
            console.log('LOCAL', buf?.byteLength, 'REMOTE', (n === chunks - 1) ? lastChunk : chunkSize);
            const start = chunkSize * n;
            const end = (start + chunkSize > size) ? size : start + chunkSize;
            if (!buf || ((n === chunks - 1) ? (buf.byteLength !== lastChunk) : (buf.byteLength !== chunkSize))) {
                // missing or incomplete chunk
                const start = chunkSize * n;
                const end = (start + chunkSize > size) ? size : start + chunkSize;
                console.debug('RANGED', start, end);
                const buf2 = await getArrayBuffer(url, progress, start, end - 1);
                await dbPut('encrypted', [id, n], buf2);
                downloaded += buf2.byteLength;
            } else {
                console.debug(`CHECKED ${n} [${start}-${end}]`);
                await progress.setProgress(end);
                checked += buf.byteLength;
            }
        }
        return {downloaded, checked};
    } else {
        console.debug('UNRANGED');
        const data = await getArrayBuffer(url, progress);
        await storeExamChunks(id, 0, data);
        return {downloaded: data.byteLength, checked: 0};
    }
}

export async function fetchExams(localExamMap: ExamMap, fetchList: FetchExamItem[], progress: Progress): Promise<boolean> {
    const sizes = await getExamSizes(fetchList);
    let {failCount, alerts} = sizes;
    const {sizes: heads} = sizes;
    console.debug('HEADS', heads);
    const downloadCount = fetchList.length;
    const totalSize = Array.from(heads.values()).reduce((a, b) => a + b.size, 0);

    if (totalSize === 0) {
        if (alerts) {
            throw alerts;
        }
        return false;
    }

    {
        const {used, quota} = await getFreeSpace();
        progress.setTotalSize(totalSize);
        progress.setTitle(translate('DOWNLOAD_TITLE', {downloadCount}));
        progress.setDescription(translate('DOWNLOAD_DESCRIPTION', {freeSpace: (used != null && quota != null) ? translate('FREE_SPACE', {used, quota}) : ''}));
        //await wait(3000);
        progress.onProgress = async () => {
            const {used, quota} = await getFreeSpace();
            progress.setDescription(translate('DOWNLOAD_DESCRIPTION', {freeSpace: (used != null && quota != null) ? translate('FREE_SPACE', {used, quota}) : ''}));
            //await wait(3000);
        }
    }

    console.debug('GOT SIZES', Array.from(heads.entries()));

    for (const exam of fetchList) {
        const head = heads.get(exam.link);
        if (head !== undefined) {
            const key = `${exam.id}-${exam.component}`;
            try {
                const {downloaded, checked} = await fetchExam(key, exam.link, head, progress);
                if (downloaded > 0 && downloaded + checked === head.size) {
                    const updated = {...exam, size: head.size, ranged: head.ranged};
                    await dbPut('exams', key, updated);
                    localExamMap.set(key, updated);
                    await getText(`/app/${exam.id}/downloaded/`);
                } else if (downloaded + checked < head.size) {
                    alerts += `Downloading ${exam.title}: Incomplete data transfer`;
                }
            } catch(err) {
                if (err instanceof HttpError && err.status == 412) {
                    alerts += translate('DOWNLOAD_COUNT_EXCEEDED', {examTitle: exam.title});
                } else if (err instanceof StorageError) {
                    throw err;
                } else {
                    console.error(String(err));
                    alerts += `Downloading ${exam.title}: ${String(err)}\n`;
                }
                ++failCount;
            }
        }
    }
    if (alerts) {
        throw alerts;
    }
    return fetchList.length > failCount;
}

//----------------------------------------------------------------------------
// Prepare Exam

class ArrayBufferWriter extends zip.Writer {
    private u8: Uint8Array;
    private p: number;
    private length: number;

    public constructor(length: number) {
        super();
        this.length = length;
        this.u8 = new Uint8Array(this.length);
        this.p = 0;
    }

    public init(callback: () => void, onerror: (err: unknown) => void): void {
        try {
            this.p = 0;
            callback();
        } catch (err) {
            console.error(err);
            onerror(err);
        }
    }

    public writeUint8Array(array: Uint8Array, callback: () => void, onerror: (err: unknown) => void): void {
        try {
            this.u8.set(array, this.p);
            this.p += array.length;
            callback();
        } catch (err) {
            console.error(err);
            onerror(err);
        }
    }

    public getData(callback: (buf: ArrayBuffer) => void): void {
        callback(this.u8.buffer);
    }
}

export class DecryptionError extends Error {
    constructor(message: string) {
        super(message);
        Object.setPrototypeOf(this, new.target.prototype);
    }
}

function countImages(images: PractiqueNet.ExamJson.Definitions.Image[]) {
    let x = 0;
    for (const img of images) {
        if (isImageSet(img)) {
            x += img.set.length;
        } else {
            ++x;
        }
    }
    return x;
}

function countQuestionsImages(used: Set<number>, questions: PractiqueNet.ExamJson.Definitions.Question[]) {
    let x = 0;
    for (const question of questions) {
        if (used.has(question.backend_id)) {
            //console.log('COUNT', question.backend_id);
            x += countImages(question.images);
            if (question.answersResources) {
                for (const answerResources of question.answersResources) {
                    x += countImages(answerResources);
                }
            }
        }
    }
    return x;
}

function countExamImages(used: Set<number>, exam: PractiqueNet.ExamJson): number {
    let x = countQuestionsImages(used, exam.questions);
    if (exam.variantQuestions) {
        for (const variant of exam.variantQuestions) {
            x += countQuestionsImages(used, variant);
        }
    }
    return x;
}

export interface Storage {
    unpackExam: (key: string, examId: ExamId, candidateId: string, size: number, title: string, progress: Progress) => Promise<{manifest: PractiqueNet.ExamJson, structure: Structure[]}>;
    getImageBegin: () => Promise<void>;
    getImageFrame: (start: number, end: number) => Promise<Uint8Array|undefined>;
    getImageEnd: () => Promise<void>;
}

function getStructure(manifest: PractiqueNet.ExamJson, candidateId: string): {structure: Structure[], used: Set<number>} {
    let sections: PractiqueNet.ExamJson.Definitions.Section[] | undefined = undefined;
    if (manifest.candidateSections) { // if sections provided lookup this CID.
        sections = manifest.candidateSections[candidateId];
    }
    if (sections == undefined && manifest.defaultSections) {
        sections = manifest.defaultSections;
    }
    const questionMap = new Map<number, number>();
    if (sections == undefined) {
        const order = [];
        for (let i = 0; i < manifest.questions.length; ++i) {
            const question = manifest.questions[i];
            order.push({ question: question.backend_id });
            questionMap.set(question.backend_id, i);
        }
        sections = [{
            items: order
        }];
    } else {
        for (let i = 0; i < manifest.questions.length; ++i) {
            questionMap.set(manifest.questions[i].backend_id, i);
        }
    }
    console.debug('ORIGINAL SECTIONS:', sections);
    const examHash = crc32(manifest.exam.answer_aes_key);
    const candHash = crc32(candidateId);
    const random = new PCG32(new UInt64(candHash, examHash), new UInt64(examHash, candHash));
    for (const section of sections) {
        if (section.randomize || manifest.exam.randomize) {
            shuffle(random, section.items);
        }
    }
    console.debug('FINAL SECTIONS:', sections);
    const used = new Set<number>();
    let index = 0;
    let num = 1;
    const structure = [] as Structure[];
    for (const section of sections) {
        if (section.restartNumbering) {
            num = 1;
        }
        for (const item of section.items) {
            const cases = (item.cases != undefined) ? item.cases :
                (item.question != undefined) ? [item.question] :
                [];
            console.debug('CASES', cases);
            if (cases.length > 0) {
                for (const {question, caseIx} of cases.map((question, caseIx) => ({question, caseIx}))) {
                    used.add(question);
                    const i = questionMap.get(question);
                    console.debug('MAP:', question, i);
                    if (i != undefined) {
                        const question = manifest.questions[i];
                        const variantIds = [question.backend_id];
                        if (manifest.variantQuestions) {
                            const x = manifest.variantQuestions[i];
                            for (let j = 0; j < x.length; ++j) {
                                variantIds.push(x[j].backend_id);
                                used.add(x[j].backend_id);
                            }
                        }
                        console.debug('VARIANT_IDS', variantIds);
                        for (const answer of question.answers) {
                            if (answer.type === 'label') {
                                answer.mandatory = false; // make sure no label is marked mandatory
                            } else if (manifest.exam.disableBackwardsNavigation) {
                                answer.mandatory = true; // force answers to mandatory if backward navigation disabled
                            }
                        }
                        structure.push({
                            backendQid: variantIds,
                            backendAid: question.answers.map(a => a.backend_id),
                            answerType: question.answers.map(a => a.type),
                            indents: question.answers.map(a => a.indent ?? 0),
                            displayQstNumber: `${num++}`,
                            displayAnsNumber: question.answers.map(a => a.displayNumber),
                            length: question.answers.length,
                            visible: question.answers.map(a => a.visible),
                            question: index++,
                            factor: item.factor,
                            interview: item.interviewId,
                            round: item.round,
                            circuit: item.circuit,
                            room: item.room,
                            case: caseIx + 1,
                            ofCases: cases.length,
                            mandatory: question.answers.map(a => a.mandatory === true),
                        });
                    }
                }
            } else {
                if (!manifest.exam.hideEmptyItems) {
                    structure.push({
                        backendQid: [],
                        backendAid: [],
                        answerType: [],
                        indents: [],
                        displayQstNumber: `${num++}`,
                        displayAnsNumber: [],
                        length: 0,
                        visible: [],
                        question: index++,
                        factor: item.factor,
                        interview: item.interviewId,
                        round: item.round,
                        circuit: item.circuit,
                        room: item.room,
                        mandatory: [],
                    });
                }
            }
        }
    }
    return {structure, used};
}

async function zipGetEntries(reader: IndexedDBReader): Promise<zip.Entry[]> {
    console.debug('ZIP_GET_ENTRIES');
    return new Promise((succ, fail) => {
        zip.createReader(reader, (zipReader) => {
            console.debug('zip.createReader');
            try {
                zipReader.getEntries(succ);
            } catch (err) {
                fail(new DecryptionError(String(reader.lastError || err)));
            }
        }, (err: unknown) => {
            fail(new DecryptionError(String(reader.lastError || err)));
        });
    });
}

async function zipExtractText(entry: zip.Entry): Promise<string> {
    return new Promise<string>((succ): void => {
        entry.getData(new zip.TextWriter('text/plain'), succ);
    });
}

async function zipExtractArraybuffer(entry: zip.Entry): Promise<ArrayBuffer> {
    return new Promise<ArrayBuffer>((succ): void => {
        entry.getData(new ArrayBufferWriter(entry.uncompressedSize), succ);
    });
}

async function zipExtract(entry: zip.Entry, writer: zip.Writer): Promise<void> {
    return new Promise<void>((succ): void => {
        entry.getData(writer, succ);
    });
}

/*
async function zipGetEntries(key: string, examId: string, readChunkSize: number, size: number): Promise<Entry[]> {
    try {
        const indexedDBReader = new IndexedDBReader('encrypted', key, examId, readChunkSize, size);
        const zipReader = new ZipReader(indexedDBReader);
        return await zipReader.getEntries();
    } catch (err) {
        throw new DecryptionError(String(err));
    }
}
*/

export class StoreChunks implements Storage {
    private readChunkSize: number;
    private writeChunkSize: number;
    private imageReader: IDBReader | null;

    public constructor(readChunkSize: number, writeChunkSize: number) {
        this.readChunkSize = readChunkSize;
        this.writeChunkSize = writeChunkSize;
        this.imageReader = null;
    }

    public async unpackExam(key: string, examId: ExamId, candidateId: string, size: number, title: string, progress: Progress):
        Promise<{manifest: PractiqueNet.ExamJson, structure:Structure[]}>
    {
        console.debug('BEGIN UNZIP');
        const t0 = performance.now();

        {
            const {used, quota} = await getFreeSpace();
            progress.setTitle(translate('PREPARING_TITLE'));
            progress.setDescription(translate('PREPARING_DESCRIPTION', {freeSpace: (used != null && quota != null) ? translate('FREE_SPACE', {used, quota}) : ''}));
            //await wait(3000);
            progress.onProgress = async () => {
                const {used, quota} = await getFreeSpace();
                progress.setDescription(translate('PREPARING_DESCRIPTION', {freeSpace: (used != null && quota != null) ? translate('FREE_SPACE', {used, quota}) : ''}));
                //await wait(3000);
            }
        }

        const entries = await zipGetEntries(new IndexedDBReader('encrypted', key, examId, this.readChunkSize, size));
        console.debug('ENTRIES', entries);
        const files = entries.reduce((map, obj): Map<string, zip.Entry> => {
            map.set(obj.filename, obj);
            return map;
        }, new Map<string, zip.Entry>());
        console.debug('ENTRIES MAP', files);
        const writer = new IDBWriter('images', this.writeChunkSize);
        const prepare = new PrepareExam(writer, files, progress, title);
        const manifest = await prepare.unpackManifest();
        console.debug('MANIFEST', manifest);

        if (manifest.candidates.indexOf(candidateId) < 0) {
            throw translate('ERROR_CANDIDATE_NOT_FOUND');
        }

        const {structure, used} = getStructure(manifest, candidateId);

        progress.setTotalSize(1 + countExamImages(used, manifest));
        await progress.setProgress(1);

        await prepare.unpackQuestions(used, manifest.questions);
        manifest.questions.splice(0, manifest.questions.length);
        if (manifest.variantQuestions) {
            for (const variants of manifest.variantQuestions) {
                await prepare.unpackQuestions(used, variants);
                variants.splice(0, variants.length);
            }
            manifest.variantQuestions.splice(0, manifest.variantQuestions.length);
        }
        await writer.closePromise();

        console.debug('TIMING', manifest.exam.timing);

        const t1 = performance.now();
        console.info('UNPACKING TIME: ', (t1 - t0), 'ms');
        return {manifest, structure};
    }

    public async getImageBegin(): Promise<void> {
        this.imageReader = new IDBReader('images', this.writeChunkSize);
    }

    public async getImageFrame(start: number, end: number): Promise<Uint8Array|undefined> {
        //console.debug('GET_IMAGE_FRAME', start, end);
        return (end > start) ? await this.imageReader?.readUint8ArrayPromise(start, end) : undefined;
    }

    /*
    public getImageBlob(image: Img, frame: number): Promise<Blob> {
        if (image.dbOffset == null) {
            return Promise.reject('db_offset is null');
        }
        let start = image.dbOffset;
        for (let i = 0; i < frame; ++i) {
            start += image.dataSize[i];
        }
        const end = start + image.dataSize[frame];
        if (this.imageReader == null) {
            return Promise.reject('image_reader is null');
        }
        return this.imageReader.readBlob(start, end);
    }
    */

    public async getImageEnd(): Promise<void> {
        if (this.imageReader != null) {
            this.imageReader.close();
            this.imageReader = null;
        }
    }
}


class PrepareExam {
    private writer: IDBWriter;
    private files: Map<string, zip.Entry>;
    private progress: Progress;
    private count = 1;
    //private offset: Map<string, number> = new Map();
    private title: string

    constructor(writer: IDBWriter, files: Map<string, zip.Entry>, progress: Progress, title: string) {
        this.writer = writer;
        this.files = files;
        this.progress = progress;
        this.title = title;
    }

    private async writeFrames(frames: Frame[]): Promise<void> {
        for (let i = 0; i < frames.length; ++i) {
            const data = frames[i].data;
            if (data != undefined) {
                frames[i].dbOffset = this.writer.getPtr();
                await this.writer.writeUint8ArrayPromise(new Uint8Array(data));
                frames[i].data = undefined;

            }
        }
        await this.progress.setProgress(++this.count);
    }

    private async writeFramesWithOverlays(frames: DicomFrame[]): Promise<void> {
        for (let i = 0; i < frames.length; ++i) {
            const data = frames[i].data;
            if (data != undefined) {
                frames[i].dbOffset = this.writer.getPtr();
                await this.writer.writeUint8ArrayPromise(new Uint8Array(data));
                frames[i].data = undefined;
                for (let j = 0; j < frames[i].overlayRects.length; ++j) {
                    const bitmap = frames[i].overlayBitmaps[j];
                    if (bitmap != undefined) {
                        await this.writer.writeUint8ArrayPromise(new Uint8Array(bitmap));
                        frames[i].overlayBitmaps[j] = undefined;
                    }
                }
            }
        }
        await this.progress.setProgress(++this.count);
    }

    public async unpackDicomSet(imageSet: PractiqueNet.ExamJson.Definitions.ImageSet): Promise<{
        thumbnail?: ImageData, dicom?: Img
    }> {
        if (imageSet.set.length <= 0) {
            throw 'no images';
        }

        let thumbnail: ImageData | undefined;
        let dicom: DicomImg | undefined;
        for (let i = 0; i < imageSet.set.length; ++i) {
            const frame = imageSet.set[i];
            const entry = this.files.get(frame);
            if (entry) {
                try {
                    const data = await zipExtractArraybuffer(entry);
                    if (dicom == undefined) {
                        dicom = await makeDicom(data, frame);
                        dicom.caption = imageSet.caption || '';
                        dicom.distribution = imageSet.distribution;
                        dicom.use_dicom_order = imageSet.use_dicom_order ?? true;
                        const renderer = new DicomRenderer(dicom);
                        thumbnail = await renderer.renderThumbnail();
                        await this.writeFramesWithOverlays(dicom.frames);
                    } else {
                        await addDicom(dicom, data);
                        await this.writeFramesWithOverlays(dicom.frames);
                    }
                } catch (err) {
                    if (err instanceof Error) {
                        //err.message = 'resource ' + frame + ', ' + err.message;
                        console.error('resource ' + frame + ', ' + err.message);
                        throw err;
                    } else {
                        throw new Error('resource ' + frame + ', ' + String(err));
                    }
                }
            }
        }
        return {thumbnail, dicom};
    }

    public async unpackImageStd(image: PractiqueNet.ExamJson.Definitions.ImageSingle): Promise<{
        thumbnail?: ImageData, std?: Img
    }> {
        let thumbnail = undefined;
        let std = undefined;
        const entry = this.files.get(image.image);
        if (entry) {
            try {
                const data = await zipExtractArraybuffer(entry);
                const renderer = tiffDetect(data)
                    ? new TiffRenderer(makeTiff(image.image, [data]))
                    : (j2kDetect(data)
                        ? new J2kRenderer(makeJ2k(image.image, [data]))
                        : new StdRenderer(makeStd(image.image, [data]))
                    );
                std = renderer.img;
                std.caption = image.caption || '';
                std.distribution = image.distribution;
                thumbnail = await renderer.renderThumbnail();
                await this.writeFrames(std.frames);
            } catch (err) {
                throw new Error('resource ' + image.image + ', ' + String(err));
            }
        }
        return {thumbnail, std};
    }

    public async unpackAudio(resource: PractiqueNet.ExamJson.Definitions.Resource): Promise<{
        thumbnail?: ImageData, audio?: Img
    }> {
        let audio = undefined;
        const entry = this.files.get(resource.file);
        if (entry) {
            const offset = this.writer.getPtr();
            await zipExtract(entry, this.writer);
            const length = this.writer.getPtr() - offset;
            audio = {
                id: resource.file,
                iType: IType.Audio,
                frames: [{
                    dbOffset: offset,
                    dataSize: length,
                }],
                caption: resource.caption || '',
                distribution: resource.distribution,
                mime: resource.mime ?? undefined,
                frameCount: 1,
            };
            await this.progress.setProgress(++this.count);
        }
        return {audio};
    }

    public async unpackVideo(resource: PractiqueNet.ExamJson.Definitions.Resource): Promise<{
        thumbnail?: ImageData, video?: Img
    }> {
        let video = undefined;
        const entry = this.files.get(resource.file);
        if (entry) {
            const offset = this.writer.getPtr();
            await zipExtract(entry, this.writer);
            const length = this.writer.getPtr() - offset;
            video = {
                id: resource.file,
                iType: IType.Video,
                frames: [{
                    dbOffset: offset,
                    dataSize: length,
                }],
                caption: resource.caption || '',
                distribution: resource.distribution,
                mime: resource.mime ?? undefined,
                frameCount: 1,
            };
            await this.progress.setProgress(++this.count);
        }
        return {video};
    }

    public async unpackPdf(resource: PractiqueNet.ExamJson.Definitions.Resource): Promise<{
        thumbnail?: ImageData, pdf?: Img
    }> {
        let thumbnail = undefined;
        let pdf = undefined;
        const entry = this.files.get(resource.file);
        if (entry) {
            const data = await zipExtractArraybuffer(entry);
            const renderer = new PdfRenderer(makePdf(resource.file, [data]));
            pdf = renderer.img;
            pdf.caption = resource.caption || '';
            pdf.distribution = resource.distribution;
            thumbnail = await renderer.renderThumbnail();
            await this.writeFrames(pdf.frames);
            renderer.destroy();
        }
        return {thumbnail, pdf};
    }

    public async unpackResources(images: PractiqueNet.ExamJson.Definitions.Image[]): Promise<{
        thumbnails: (ArrayBuffer|null)[], resources: Img[]
    }> {
        const thumbnails = [];
        const resources: Img[] = [];
        for (let j = 0; j < images.length; ++j) {
            const image = images[j];
            if (isImageSet(image)) {
                const {dicom, thumbnail} = await this.unpackDicomSet(image);
                if (dicom) {
                    resources.push(dicom);
                }
                if (thumbnail) {
                    thumbnails.push(thumbnail?.data.buffer ?? null);
                    console.debug(`THUMB[${thumbnails.length - 1}] ${thumbnail.width}x${thumbnail.height}`);
                }
            } else if (isImageSingle(image)) {
                const imageStd = await this.unpackImageStd(image);
                if (imageStd.std) {
                    resources.push(imageStd.std);
                }
                if (imageStd.thumbnail) {
                    thumbnails.push(imageStd.thumbnail?.data.buffer ?? null);
                }
            } else if (isResource(image)) {
                switch (image.type) {
                    case 'video':
                        const resourceV = await this.unpackVideo(image);
                        if (resourceV.video) {
                            resources.push(resourceV.video);
                            thumbnails.push(null);
                        }
                        break;
                    case 'audio':
                        const resourceA = await this.unpackAudio(image);
                        if (resourceA.audio) {
                            resources.push(resourceA.audio);
                            thumbnails.push(null);
                        }
                        break;
                    case 'pdf':
                        const imagePdf = await this.unpackPdf(image);
                        if (imagePdf.pdf) {
                            resources.push(imagePdf.pdf);
                        }
                        if (imagePdf.thumbnail) {
                            thumbnails.push(imagePdf.thumbnail?.data.buffer ?? null);
                        }
                        break;
                    default:
                        console.error('UNKNOWN RESOURCE', image);
                        thumbnails.push(null);
                        resources.push({
                            id: '',
                            iType: IType.Unknown,
                            frames: [],
                            frameCount: 0,
                        });
                        break;
                }
            } else {
                console.error('UNKNOWN FILE TYPE');
                thumbnails.push(null);
                resources.push({
                    id: '',
                    iType: IType.Unknown,
                    frames: [],
                    frameCount: 0,
                });
            }
        }
        return {thumbnails, resources};
    }

    public async unpackQuestions(used: Set<number>, questions: PractiqueNet.ExamJson.Definitions.Question[]): Promise<void> {
        for (const manifest of questions) {
            if (used.has(manifest.backend_id)) {
                //console.log('UNPACK', manifest.backend_id);
                try {
                    let thumbnails: (ArrayBuffer|null)[] = []
                    let images: Img[] = [];
                    const answersResources: AnswerResources[] = [];
                    if (manifest.images.length > 0) {
                        const unpacked = await this.unpackResources(manifest.images);
                        thumbnails = unpacked.thumbnails;
                        images = unpacked.resources;
                    }
                    if (manifest.answersResources) {
                        for (const packed of manifest.answersResources) {
                            const unpacked = await this.unpackResources(packed);
                            answersResources.push(unpacked);
                        }
                    }
                    for (const answer of manifest.answers) {
                        if (answer.type === 'hotspot' && answer.resource) {
                            const packed = [answer.resource];
                            const unpacked = await this.unpackResources(packed);
                            thumbnails = [...thumbnails, ...unpacked.thumbnails];
                            images = [...images, ...unpacked.resources];
                            break; // only get first image
                        }
                    }
                    await dbPut('questions', manifest.backend_id, {manifest, images, thumbnails, answersResources} as QuestionManifest);
                } catch (err) {
                    if (err instanceof Error) {
                        //err.message = 'Question ' + manifest.backend_id + ', ' + err.message;
                        console.error('Question ' + manifest.backend_id + ', ' + err.message);
                        throw err;
                    } else {
                        throw new Error('Question ' + manifest.backend_id + ', ' + String(err));
                    }
                }
            }
        }
    }

    public async unpackManifest(): Promise<PractiqueNet.ExamJson> {
        const manifestEntry = this.files.get('manifest.json');
        if (!manifestEntry) {
            throw 'Exam file has no manifest.';
        }
        const data = await zipExtractText(manifestEntry);
        const maybeExam = safeJsonParse(data);
        if (!isExam(maybeExam)) {
            console.error('VALIDATION ERROR', examValidate.errors)
            console.error('EXAM DATA', maybeExam);
            throw new ValidationError(`<b>Invalid exam: ${this.title}</b><p>${examValidate.errors.map(x => x.message).join('<br>')}</p>`);
        }
        return maybeExam;
    }
}

export class ValidationError extends Error {
    constructor(message: string) {
        super(message);
        Object.setPrototypeOf(this, new.target.prototype);
    }
}

/*
var storeBlobs = (function() {
    function unpackExam(state: State, key: string, exam_id: string, chunkSize: number, size: number) {
        utils.log('BEGIN UNZIP');
        var t0 = performance.now();

        var unpack_progress = {
            ui : utils.make_elements(progress_ui),
        } as ProgressUi;
        unpack_progress.ui.title.textContent = 'Preparing exam, please wait...';
        unpack_progress.ui.subtext.textContent = 'Preparing can take several minutes depending on your computer specification.';
        unpack_progress.ui.text.textContent = '0%';
        wi.progress.appendChild(unpack_progress.ui.progress_box);
        function progress(v: number) {
            unpack_progress.ui.text.textContent = (100 * v | 0) + '%';
        }

        state.structure = [];
        return zip_get_entries(new IndexedDBReader('encrypted', key, exam_id, chunkSize, size)).then(function(entries) {
                var files = entries.reduce(function(map, obj) {
                    map.set(obj.filename, obj);
                    return map;
                }, new Map());
                var manifest_entry = files.get('manifest.json');
                return zip_extract_text(manifest_entry).then(function(data) {
                    var exam = JSON.parse(data);
                    if (!exam) {
                        throw 'manifest not found.';
                    }
                    utils.log('got manifest.');
                    if (exam.candidates.indexOf(state.exam_cid) < 0) {
                        throw 'candidate not found';
                    }
                    var len = count_exam_images(exam);
                    var idx = 1;
                    progress(idx / len);
                    if (exam.exam && exam.exam.answer_aes_key) {
                        state.this_exam = exam.exam;
                    } else {
                        state.this_exam = {'answer_aes_key': exam.answer_aes_key};
                    }
                    var max_heap_size = 0;
                    return exam.questions.reduce(function(promise1: Promise<void>, question: QuestionManifest, i: number) {
                        return promise1.then(function() {
                            question.id = i;
                            utils.log('BEGIN IMAGES');
                            var t2 = performance.now();
                            return question.images.reduce(function(promise2, image, j) {
                                return promise2.then(function() {
                                    if (image.set) {
                                        var db_offset = j;
                                        var dicom: dicom_viewer.DicomData = null;
                                        var data_size: number[] = [];
                                        var sizes: [number, number][] = [];
                                        var blob = new Blob([]);
                                        return image.set.reduce(function(promise3: Promise<number>, frame: string, k: number) {
                                            return promise3.then(function(cnt) {
                                                var entry = files.get(frame);
                                                return zip_extract_arraybuffer(entry).then(function(data) {
                                                    var new_dicom = dicom_viewer.make_dicom(data);
                                                    data = null;
                                                    if (!dicom || dicom_viewer.compatible(dicom, new_dicom)) {
                                                        dicom = new_dicom;
                                                        dicom.data.forEach(function(frame, x) {
                                                            data_size.push(frame.byteLength);
                                                            sizes.push([dicom.cols, dicom.rows]);
                                                            blob = new Blob([blob, frame]);
                                                            dicom.data[x] = null;
                                                            ++cnt;
                                                        })
                                                    }
                                                    progress(++idx / len);
                                                    return cnt;
                                                });
                                            });
                                        }, Promise.resolve(0)).then(function(cnt: number) {
                                            return storeImage([i, j], blob).then(function() {
                                                blob = null;
                                                max_heap_size = Math.max(max_heap_size, dicom_viewer.heap_size(dicom));
                                                dicom.data = [];
                                                dicom.frames = cnt;
                                                dicom.db_offset = db_offset;
                                                dicom.dataSize = data_size;
                                                dicom.sizes = sizes;
                                                dicom.cols = sizes[0][0];
                                                dicom.rows = sizes[0][1];
                                                question.images[j] = dicom;
                                                dicom = null;

                                            });
                                        });
                                    }
                                });
                            }, Promise.resolve()).then(function() {
                                var t3 = performance.now();
                                utils.log('MAX HEAP SIZE:', max_heap_size.toString(16));
                                utils.log('IMAGES TIME:', (t3 - t2), 'ms');
                            });
                        }).then(function() {
                            return store_question(question);
                        }).then(function() {
                            exam.questions[i] = null;
                        });
                    }, Promise.resolve()).then(function() {
                        //reader.close();
                        wi.progress.removeChild(unpack_progress.ui.progress_box);
                        state.question_count = exam.questions.length;
                        var t1 = performance.now();
                        utils.log('UNPACKING TIME: ', (t1 - t0), 'ms');
                        state.webcrypto_aes_key = null;
                        state.asmcrypto_aes_key = null;
                        return state;
                    });
                });
        }).catch(function(err) {
            wi.progress.removeChild(unpack_progress.ui.progress_box);
            utils.log('error: unpackExam:', err);
            state.webcrypto_aes_key = null;
            state.asmcrypto_aes_key = null;
            return Promise.reject(err);
        });
    }

    function getThumbnails(state) {
        if (state.question.images.length === 0) {
            utils.log('getThumbnails: no images');
            return Promise.resolve(state);
        }
        utils.log('getThumbnails: start images');
        return state.question.images.reduce(function(promise, image, j) {
            return promise.then(function() {
                utils.log('getThumbnails: ', [state.question.id, j], image.dataSize[0]);
                return db_get('images', [state.question.id, j]);
            }).then(function(blob) {
                return readBlob(blob.slice(0, image.dataSize[0])).then(function(buf) {
                    image.data.push(buf);
                });
            });
        }, Promise.resolve()).then(function() {
            utils.log('getThumbnails: end images');
            return state;
        });
    }

    function getImage(image) {
        var t0 = performance.now();
        return db_get('images', [state.question.id, image.db_offset]).then(function(blob) {
            var size = image.dataSize;
            return utils.fold(new utils.RangeIterator(0, image.frames), Promise.resolve(0), function(promise, frame, i) {
                return promise.then(function(start) {
                    var end = start + size;
                    return readBlob(blob.slice(start, end)).then(function(buf) {
                        image.data[frame] = buf;
                        return end;
                    });
                });
            });
        }).then(function() {
            var t1 = performance.now();
            utils.log('I [', state.question.id, image.db_offset, '] ', t1 - t0, 'ms');
        });
    }

    var blob;
    function getImageBegin(image) {
        return db_get('images', [state.question.id, image.db_offset]).then(function(b) {
            blob = b;
        });
    }

    function getImageFrame(image, frame) {
        var start = 0;
        for (var i = 0; i < frame; ++i) {
            start += image.dataSize[i];
        }
        var end = start + image.dataSize[frame];
        return readBlob(blob.slice(start, end))
    }

    function getImageEnd() {
        blob = null;
    }

    return {
        unpackExam : unpackExam,
        getThumbnails : getThumbnails,
        getImage : getImage,
        getImageBegin : getImageBegin,
        getImageFrame : getImageFrame,
        getImageEnd : getImageEnd
    }
})();
*/

//----------------------------------------------------------------------------
// Response Data Model

export type Json = string | number | boolean | null | Json[] | { [key: string]: Json | undefined };

function isJson(x: unknown): x is Json {
    return typeof x === 'string' ||
        typeof x === 'number' ||
        typeof x === 'boolean' ||
        (typeof x === 'object' && x === null) ||
        isIndexed(x);
}

export enum ResponseStatus {
    emptyLocal = 'EMPTY_LOCAL',
    savedLocal = 'SAVED_LOCAL',
    emptyRemote = 'EMPTY_REMOTE',
    savedRemote = 'SAVED_REMOTE',
}

export interface LocalData extends AnswerValue {
    elapsed: number;
    timeOnQuestion?: number;
}

export interface RemoteData extends LocalData {
    exam: string;
    responder: string;
    question: number;
    response: number;
    factor?: string;
}

function isLocalData(x: unknown): x is LocalData {
    return isIndexed(x) &&
        typeof x.elapsed === 'number' &&
        (typeof x.answer === 'undefined' || isJson(x.answer)) &&
        (typeof x.extra === 'undefined' || isJson(x.extra));
}

interface Flags {
    qid: number;
    aid: number;
    flag: boolean;
}

type Expr = PractiqueNet.ExamJson.Definitions.Expr;

export interface StatusResponse {
    state?: ExamState;
    elapsed?: number;
    schedule?: PractiqueNet.ExamJson.Definitions.Schedule;
    responses?: unknown[];
    timestamp?: number;
    recv_timestamp?: number;
    returnResponses?: boolean;
    scheduleVersion?: number;
    checkId?: string;
    checkValue?: boolean|null;
}

export type RemoteStatus = Omit<StatusResponse, 'responses'>;

export function isStatusResponse(x: unknown): x is StatusResponse {
    if (!isIndexed(x)) {
        console.warn('StatusResponse is not an object');
        return false;
    }
    if (!(typeof x.state !== 'undefined' || (typeof x.state === 'string' && isState(x.state)))) {
        console.warn('StatusResponse has invalid state');
        return false;
    }
    if (!(typeof x.elapsed === 'undefined' || typeof x.elapsed === 'number')) {
        console.warn('StatusResponse has invalid elsapsed time');
        return false;
    }
    if (!(typeof x.responses === 'undefined' || Array.isArray(x.responses))) {
        console.warn('StatusResponse has invalid responses');
        return false;
    }
    if (!(typeof x.schedule === 'undefined' || isSchedule(x.schedule))) {
        console.warn('StatusResponse has invalid schedule');
        return false;
    }
    return true;
}

function isRemoteData(x: unknown): x is RemoteData {
    return isIndexed(x) && isLocalData(x) &&
        typeof x.exam === 'string' &&
        typeof x.responder === 'string' &&
        typeof x.question === 'number' &&
        typeof x.response === 'number' &&
        (typeof x.factor === 'undefined' || typeof x.factor === 'string');
}

export class ResponseModel {
    private examId: string;
    private candidateId: string;
    private items: Structure[];
    private demo: boolean;

    constructor(examId: string, candidateId: string, demo: boolean, items: Structure[]) {
        this.examId = examId;
        this.candidateId = candidateId;
        this.items = items;
        this.demo = demo;
    }

    remote({itemIndex, fieldIndex, local} : {itemIndex: number, fieldIndex: number, local: LocalData}): RemoteData {
        return {
            exam: this.examId,
            responder: this.candidateId,
            question: this.items[itemIndex]?.backendQid[0], // aways use primary vairant
            response: fieldIndex < 0 ? 0 : this.items[itemIndex]?.backendAid[fieldIndex],
            factor: this.items[itemIndex]?.factor,
            ...local,
        };
    }

    /*
    async postAnswer(itemIndex: number, fieldIndex: number, local: LocalData): Promise<void> {
        if (this.demo) {
            return;
        }
        const remval = this.remote({itemIndex, fieldIndex, local})
        const factor = this.items[itemIndex].factor;
        if (factor != undefined) {
            remval.factor = factor;
        }
        try {
            await postJson(
                remval,
                '/app/answers/'
                + remval.exam + '/'
                + remval.responder + '/'
                + remval.question + '/'
                + remval.response
            );
        } catch(err) {
            if (!(err instanceof HttpError) || err.status != 404) {
                throw err;
            } else {
                console.error('POST_ANSWER', String(err));
            }
        }
    }
    */

    /*
    async setAnswerRemote({qno, ano}: {qno: number, ano: number}, value: LocalData, onStatus: (i: number, j: number, s: ResponseStatus) => void): Promise<boolean> {
        try {
            const key = [this.examId, this.candidateId, qno, ano];
            await this.postAnswer(qno, ano, value);
            const st2 = (value.answer === null) ? ResponseStatus.emptyRemote : ResponseStatus.savedRemote;
            await dbPut('status', key, st2);
            onStatus(qno, ano, st2);
            return true;
        } catch (err) {
            console.error('SET_ANSWER_REMOTE', String(err));
            return false;
        }
    }
    */

    async getLastQuestion(): Promise<LocalData|undefined> {
        let last: LocalData | undefined;
        await dbCursor('answers', IDBKeyRange.bound(
            [this.examId, this.candidateId, 0, -1],
            [this.examId, this.candidateId, Number.MAX_SAFE_INTEGER, -1]
        ), cursor => {
            // NOTE: We have to check last key element is '-1' because IndexedDB Array keys are compared left to right.
            if (Array.isArray(cursor.key) && cursor.key.length >= 4 && cursor.key[3] === -1 && isLocalData(cursor.value)) {
                if (last === undefined || cursor.value.elapsed > last.elapsed) {
                    last = cursor.value;
                }
            }
        });
        return last;
    }

    /*async resendAnswer(itemIndex: number, fieldIndex: number, onStatus: (i: number, j: number, s: ResponseStatus) => void): Promise<boolean> {
        const key = [this.examId, this.candidateId, itemIndex, fieldIndex];
        console.debug('RESEND:', key);
        try {
            const answer = await dbGet('answers', key);
            if (isLocalData(answer)) {
                await this.postAnswer(itemIndex, fieldIndex, answer);
                const st2 = (answer === null) ? ResponseStatus.emptyRemote : ResponseStatus.savedRemote;
                await dbPut('status', key, st2);
                onStatus(itemIndex, fieldIndex, st2);
            }
            return true;
        } catch (err) {
            alertModal(translate('ERROR_RESEND', {err: String(err)}));
            return false;
        }
    }*/

    async fetchStatus(localState: StatusResponse, silent = false): Promise<{remoteStatus?: StatusResponse, timestamp?: number}> {
        if (this.demo) {
            return {};
        }
        try {
            console.debug(`LOCAL STATUS SENT ${JSON.stringify(localState)}`);
            const {data, req_timestamp, rsp_timestamp} = await requestJson(`/app/${this.examId}/status/`, localState);
            console.debug(`REMOTE STATUS RECV ${JSON.stringify(data)}`);
            if (req_timestamp && rsp_timestamp) {
                console.debug(`STATUS FETCH TIME ${(rsp_timestamp - req_timestamp) / 1000}`);
                if (isStatusResponse(data)) {
                    const timestamp = (data.timestamp && data.recv_timestamp)
                        ? rsp_timestamp - ((rsp_timestamp - req_timestamp) - (data.timestamp - data.recv_timestamp)) / 2
                        : rsp_timestamp;
                    return {remoteStatus: data, timestamp};
                }
            }
        } catch (err) {
            console.error('FETCH_STATUS', err);
            if (!silent) {
                if (err instanceof HttpError && err.status === 502) {
                    await alertModal(translate('ERROR_PROXY'));
                } else {
                    await alertModal(translate('ERROR_RESEND', {err: String(err)}));
                }
            }
            return {};
        }
        return {};
    }

    async fetchStatusSync(localState: StatusResponse, silent = false): Promise<{remoteStatus?: StatusResponse, timestamp?: number}> {
        if (this.demo) {
            return {};
        }
        try {
            console.debug(`LOCAL STATUS SENT ${JSON.stringify(localState)}`);
            const {data, req_timestamp, rsp_timestamp} = await requestJson(`/app/${this.examId}/status/`, localState);
            console.debug(`REMOTE STATUS RECV ${JSON.stringify(data)}`);
            if (req_timestamp && rsp_timestamp) {
                console.debug(`STATUS FETCH TIME ${(rsp_timestamp - req_timestamp) / 1000}`);
                if (isStatusResponse(data)) {
                    const timestamp = (data.timestamp && data.recv_timestamp)
                        ? rsp_timestamp - ((rsp_timestamp - req_timestamp) - (data.timestamp - data.recv_timestamp)) / 2
                        : rsp_timestamp;
                    return {remoteStatus: data, timestamp};
                }
            }
        } catch (err) {
            console.error('FETCH_STATUS', err);
            if (!silent) {
                if (err instanceof HttpError && err.status === 502) {
                    await alertModal(translate('ERROR_PROXY'));
                } else {
                    await alertModal(translate('ERROR_RESEND', {err: String(err)}));
                }
            }
            return {};
        }
        return {};
    }

    async updateResponses({responses}: StatusResponse): Promise<void> {
        if (responses) {
            //const t0 = performance.now();
            const answers = [];
            const states = [];
            for (const item of responses) {
                if (isRemoteData(item)) {
                    const {exam, responder, question, response, factor, ...value} = item;
                    if (exam === this.examId && responder === this.candidateId) {
                        const qno = this.items.findIndex(x => x.backendQid[0] === question && (factor == undefined || x.factor === factor));
                        const ano = item.response === 0 ? -1 : this.items[qno].backendAid.indexOf(response);
                        const key = [exam, responder, qno, ano];
                        const status = (item.answer != null) ? ResponseStatus.savedRemote : ResponseStatus.emptyRemote;
                        answers.push({key, value});
                        states.push({key, value: status});
                    }
                }
            }
            await dbPutList('answers', answers);
            await dbPutList('status', states);
            //const t1 = performance.now();
            //console.log(`Store fetched responses: ${t1 - t0}ms`);
        }
    }

    async getStatus(onStatus: (i: number, j: number, s: ResponseStatus) => void): Promise<void> {
        await dbCursor('status', IDBKeyRange.bound(
            [this.examId, this.candidateId, Number.MIN_SAFE_INTEGER, Number.MIN_SAFE_INTEGER],
            [this.examId, this.candidateId, Number.MAX_SAFE_INTEGER, Number.MAX_SAFE_INTEGER]
        ), cursor => {
            const key = cursor.key as [string, string, number, number];
            onStatus(key[2], key[3], cursor.value);
        });
    }

    async getFlags(): Promise<Flags[]> {
        const items: Flags[] = [];
        await dbCursor('flags', IDBKeyRange.bound(
            [this.examId, this.candidateId, 0, 0],
            [this.examId, this.candidateId, Number.MAX_SAFE_INTEGER, Number.MAX_SAFE_INTEGER]
        ), cursor => items.push(cursor.value));
        return items;
    }

    async setFlag(qno: number, ano: number, flag: boolean): Promise<void> {
        return dbPut('flags', [this.examId, this.candidateId, qno, ano], {
            qid: qno,
            aid: ano,
            flag: flag
        });
    }

    async getAnswer(itemIndex: number, fieldIndex: number): Promise<LocalData|undefined> {
        const key = [this.examId, this.candidateId, itemIndex, fieldIndex];
        const response = await dbGet('answers', key);
        if (isLocalData(response)) {
            return response;
        } else {
            return undefined;
        }
    }

    /*
    async getUnsubmitted(): Promise<{itemIndex: number, fieldIndex: number, remote: RemoteData}[]> {
        const indexes: {itemIndex: number, fieldIndex: number}[] = [];
        await this.getStatus((itemIndex, fieldIndex, state) => {
            switch (state) {
                case ResponseStatus.emptyLocal:
                case ResponseStatus.savedLocal:
                    indexes.push({itemIndex, fieldIndex});
                    break;
                default:
                    break;
            }
        });
        const answers = await dbGetList('answers', indexes.map(({itemIndex, fieldIndex}): [string, string, number, number] => [this.examId, this.candidateId, itemIndex, fieldIndex]), isLocalData);
        return answers.map(({key, value}) => ({itemIndex: key[2], fieldIndex: key[3], remote: this.remote({itemIndex: key[2], fieldIndex: key[3], local: value})}));
    }*/

    async setAnswerLocal({qno, ano}: {qno: number, ano: number}, value: LocalData, onStatus: (i: number, j: number, s: ResponseStatus) => void): Promise<boolean> {
        const key = [this.examId, this.candidateId, qno, ano];
        //console.debug(`SET_ANSWER BEGIN ${JSON.stringify(key)} = ${JSON.stringify(value)}`);
        //const t0 = performance.now();
        const st1 = (value.answer === null) ? ResponseStatus.emptyLocal : ResponseStatus.savedLocal;
        try {
            const db = await openDatabase();
            await new Promise<void>((resolve, reject): void => {
                const transaction = db.transaction(['answers', 'status'], "readwrite");
                transaction.onerror = (): void => {
                    reject(toStorageError(transaction.error));
                }
                transaction.onabort = (): void => {
                    if (transaction.error) {
                        reject(toStorageError(transaction.error));
                    } else {
                        resolve(); // rollback
                    }
                }
                transaction.oncomplete = (): void => {
                    //const t1 = performance.now();
                    //console.debug(`SET_ANSWER END ${(t1 - t0) / 1000}`);
                    onStatus(qno, ano, st1);
                    resolve();
                }
                transaction.objectStore('answers').put(value, key);
                transaction.objectStore('status').put(st1, key);
            });
            return true;
        } catch (err) {
            console.error('SET_ANSWER_LOCAL', err);
            await alertModal(`Save answer failed: ${String(err)}`);
            return false;
        }
    }

    async getUnsubmitted(): Promise<{itemIndex: number, fieldIndex: number, remote: RemoteData}[]> {
        //console.log('GET_UNSUBMITTED BEGIN');
        //const t0 = performance.now();
        const db = await openDatabase();
        return await new Promise((resolve, reject): void => {
            const transaction = db.transaction(['answers', 'status'], "readonly");
            transaction.onerror = (): void => {
                reject(new StorageError(String(transaction.error)));
            };
            transaction.onabort = (): void => {
                reject(new StorageError(String(transaction.error)));
            };
            transaction.oncomplete = (): void => {
                //const t1 = performance.now();
                //console.info(`GET_UNSUBMITTED END ${(t1 - t0) / 1000}`);
                resolve(unsubmitted);
            };
            const unsubmitted:{itemIndex: number, fieldIndex: number, remote: RemoteData}[] = [];
            const answers = transaction.objectStore('answers');
            const request = transaction.objectStore('status').openCursor(IDBKeyRange.bound(
                [this.examId, this.candidateId, Number.MIN_SAFE_INTEGER, Number.MIN_SAFE_INTEGER],
                [this.examId, this.candidateId, Number.MAX_SAFE_INTEGER, Number.MAX_SAFE_INTEGER]
            ));
            request.onsuccess = (): void => {
                if (isIndexed(request.result)) {
                    const {key, value} = request.result;
                    if (key) {
                        if (Array.isArray(key) && key.length === 4 && typeof key[2] === 'number' && typeof key[3] === 'number') {
                            const itemIndex = key[2];
                            const fieldIndex = key[3];
                            switch (value) {
                                case ResponseStatus.emptyLocal:
                                case ResponseStatus.savedLocal:
                                    const req = answers.get(key);
                                    req.onsuccess = () => {
                                        if (isLocalData(req.result)) {
                                            unsubmitted.push({itemIndex, fieldIndex, remote: this.remote({itemIndex, fieldIndex, local: req.result})});
                                        }
                                    };
                                    break;
                                default:
                                break;
                            }
                        }
                    }
                    request.result['continue']();
                }
            };
        });
    }

    async setSubmitted(submitted: {itemIndex: number, fieldIndex: number, remote: RemoteData}[], onStatus: (i: number, j: number, s: ResponseStatus) => void): Promise<void> {
        //console.log('SET_SUBMITTED BEGIN');
        //const t0 = performance.now();
        const db = await openDatabase();
        const keys = submitted.map((x): [string, string, number, number] => [x.remote.exam, x.remote.responder, x.itemIndex, x.fieldIndex]);
        return await new Promise<void>((resolve, reject): void => {
            const transaction = db.transaction(['answers', 'status'], "readwrite");
            transaction.onerror = (): void => {
                reject(new StorageError(String(transaction.error)));
            }
            transaction.onabort = (): void => {
                reject(new StorageError(String(transaction.error)));
            }
            transaction.oncomplete = (): void => {
                //const t1 = performance.now();
                //console.info(`SET_SUBMITTED END ${(t1 - t0) / 1000}`);
                resolve();
            }
            const answers = transaction.objectStore('answers');
            const status = transaction.objectStore('status');
            let request: IDBRequest<unknown>;
            let i = 0;
            function next() {
                if (request) {
                    const sub = submitted[i];
                    const remote = submitted[i].remote;
                    if (isLocalData(request.result) && request.result.elapsed === remote.elapsed &&
                        JSON.stringify(request.result.answer) === JSON.stringify(remote.answer) &&
                        JSON.stringify(request.result.extra) === JSON.stringify(remote.extra)
                    ) {

                        const key: [string, string, number, number] = [remote.exam, remote.responder, sub.itemIndex, sub.fieldIndex]
                        const sts = (remote.answer === null) ? ResponseStatus.emptyRemote : ResponseStatus.savedRemote;
                        const req = status.put(sts, key);
                        req.onsuccess = () => {
                            onStatus(key[2], key[3], sts);
                        }
                    } else if (request.result) {
                        console.info(`${JSON.stringify(request.result)} <> ${JSON.stringify(remote)}`);
                    }
                    ++i;
                }
                if (i < submitted.length) {
                    request = answers.get(keys[i]);
                    request.onsuccess = next;
                }
            }
            next();
        });
    }
}

