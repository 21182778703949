import { configErrorPanel, configNtrlTextBox, configSolidPrimaryButton } from '@p4b/exam-accessibility';
import { getFreeSpace, makeElements, NodeSpecObject, removeChildren } from '@p4b/utils';
import { translate } from '@p4b/utils-lang';

interface LoginUi {
    loginBox: HTMLDivElement;
    loginRisrBox: HTMLDivElement;
    loginRisrLogo: HTMLImageElement;
    loginBodyBox: HTMLDivElement;
    loginLogo: HTMLDivElement;
    loginTextGroup: HTMLDivElement;
    loginHeader: HTMLDivElement;
    loginError: HTMLDivElement;
    loginCidLabel: HTMLDivElement;
    loginCid: HTMLInputElement;
    loginPinLabel: HTMLDivElement;
    loginPin: HTMLInputElement;
    loginButtons: HTMLDivElement;
    loginGo: HTMLInputElement;
    loginText: HTMLDivElement;
}

function loginUi(): NodeSpecObject<LoginUi> {
    const instance = window.location.host.replace(/practique\.net(:[0-9]+)?$/, '');
    return {
        loginBox: { elem: 'div', className: 'login-panel' },
        loginRisrBox: { elem: 'div', className: 'login-risr-box config-info900-bg', parent: 'loginBox' },
        loginRisrLogo: {
            elem: 'img',
            className: 'login-risr-logo',
            src: 'risr-assess-on-dark.png',
            tip: instance,
            parent: 'loginRisrBox',
            attrib: {
                alt: instance,
                draggable: 'false',
            }
        },
        loginBodyBox: {
            elem: 'div',
            className: 'login-body-box config-user100aaa-text config-user000-alpha90',
            parent: 'loginBox'
        },
        loginLogo: {
            elem: 'div', className: 'login-logo-panel', parent: 'loginBodyBox', children: [
            {
                elem: 'img',
                className: 'client-logo',
                src: '/static/images/client-login-logo.png',
                attrib: {
                    draggable: 'false',
                    onload: 'javascript:this.style.display="block"',
                }
            }
            ]
        },
        loginTextGroup: { elem: 'form', className: 'login-text-group', parent: 'loginBodyBox', attrib: {action: 'javascript:void(0);'}},
        loginHeader: {elem: 'div', className: 'login-header', parent: 'loginTextGroup', children: [
                { elem: 'html', html: translate('LOGIN_INSTRUCTIONS') }
            ]
        },
        loginError: {
            elem: 'div', parent: 'loginTextGroup', className: `login-error ${configErrorPanel}`
        },
        loginCidLabel: {
            elem: 'label', className: 'login-label', parent: 'loginTextGroup',
            children: [
                { elem: 'html', html: translate('LOGIN_CANDIDATE_ID_LABEL') }
            ]
        },
        loginCid: {
            elem: 'input', parent: 'loginCidLabel', className: `login-field ${configNtrlTextBox}`,
            attrib: { type: 'text', spellcheck: 'false', autocorrect: 'off', autocapitalize: 'none', autocomplete: 'username', /*placeholder: translate('LOGIN_CANDIDATE_ID_HINT'),*/ id: 'field-login' },
        },
        loginPinLabel: {
            elem: 'label', className: 'login-label', parent: 'loginTextGroup',
            children: [
                { elem: 'html', html: translate('LOGIN_PASSWORD_LABEL') }
            ]
        },
        loginPin: {
            elem: 'input', parent: 'loginPinLabel', className: `login-field ${configNtrlTextBox}`,
            attrib: { type: 'password', spellcheck: 'false', autocorrect: 'off', autocapitalize: 'none', autocomplete: 'current-password', /*placeholder: translate('LOGIN_PASSWORD_HINT'),*/ id: 'field-password' },
        },
        loginButtons: {
            elem: 'div',
            className: 'login-buttons',
            parent: 'loginTextGroup'
        },
        loginGo: {
            elem: 'input', className: `${configSolidPrimaryButton} login-button`, parent: 'loginButtons',
            attrib: { type: 'button', value: translate('LOGIN_BUTTON'), id: 'button-login' }
        },
        loginText: {elem: 'div', className: 'login-footer config-user100aa-text', parent: 'loginTextGroup'},
    };
}

export class Auth {
    private parent: HTMLElement;
    private li: LoginUi;

    constructor(parent: HTMLElement) {
        this.li = makeElements(loginUi()) as LoginUi;
        this.li.loginError.hidden = true;
        this.parent = parent;
    }

    destroy(): void {
        removeChildren(this.parent);
    }

    private clearError() {
        this.li.loginError.hidden = true;
        this.li.loginError.textContent = '';
    }

    async login(args: {error: string}): Promise<{username: string; password: string}> {
        const {used, quota} = await getFreeSpace();
        if (used != null && quota != null) {
            this.li.loginText.innerHTML = translate('FREE_SPACE', {used, quota});
        }
        let error = args.error;
        if (error) {
            this.li.loginError.innerHTML = error;
            this.li.loginError.hidden = false;
        } else {
            this.clearError();
        }
        //this.li.loginPinLabel.style.display = 'flex';
        this.li.loginCid.oninput = (): void => {
            if (error) {
                this.clearError()
                error = '';
            }
        }
        this.li.loginPin.oninput = (): void => {
            if (error) {
                this.clearError()
                error = '';
            }
        }
        this.parent.appendChild(this.li.loginBox);
        return await new Promise(resolve => {
            this.li.loginGo.onclick = (): void => {
                const user = this.li.loginCid.value.trim();
                const pass = this.li.loginPin.value.trim();
                if (user && pass) {
                    resolve({username: user, password: pass});
                }
            }
        });
    }
}
