import { mkNode } from '@p4b/utils';
import { Img } from '@p4b/image-base';
import { Thumbnail, ThumbnailBase } from '@p4b/thumbnail-base';

export class ResourceVideo extends ThumbnailBase implements Thumbnail {
    private video: HTMLVideoElement;
    private rows: number;

    public hide(hidden: boolean): void {
    // if video is fullscreen, exitFullscreen before hiding.
        if (document.fullscreenElement === this.video) {
            document.exitFullscreen();
        }
        if (!this.video.paused) {
            this.video.pause();
        }
        super.hide(hidden);
    }

    public constructor(image: Img, frag: Node, releaseButton = false) {
        const rows = 120;
        let video;
        super(frag, image, releaseButton, (figure) => {
            video = mkNode('video', {
                className: 'thumbnail',
                parent: figure,
                style: {visibility: 'hidden', height: rows + 'px'},
                attrib: {controls: 'true', controlsList: 'nodownload', draggable: 'false'}
            });
        });
        this.rows = rows;
        if (!video) {
            throw "Thumbnail video is null";
        }
        this.video = video;
    }

    public async load(buf: ArrayBuffer|null): Promise<void> {
        console.log('IMAGE', this.image);
        const mime = this.image.mime;
        console.log('BUFSIZE', buf?.byteLength);
        if (buf) {
            const blob = new Blob([buf], {type: mime ?? ''});
            this.video.src = URL.createObjectURL(blob);
        } else {
            console.error('video steam null');
        }
        this.video.style.visibility = 'visible';
    }

    public async open(): Promise<undefined> {
        return;
    }

    public destroy(): void {
        super.destroy();
	if (!this.video.paused) {
            this.video.pause();
        }
        if (this.video.src) {
            console.log('REVOKE VIDEO URL');
            URL.revokeObjectURL(this.video.src);
            this.video.src = '';
        }
    }
}
