import { scrollRangeIntoView } from '@p4b/utils';
import { Question, QuestionContext, QuestionManifest, QuestionBase, Expr, registerAnswerType, QuestionArgs } from '@p4b/question-base';
import { Lightbox } from '@p4b/lightbox';

/** Longtext question textarea UI */
export class QuestionLabel extends QuestionBase implements Question {
    //private answerItem: HTMLDivElement;
    //private answerLabel: HTMLDivElement;


    public readonly visibilityExpression?: Expr;

    /** Construct LabelQuestion UI */
    public constructor(args: Omit<QuestionArgs, 'showFlag'> & {
        visibilityExpression?: Expr,
    }) {
        super({...args, showFlag: false});
        const {indent, visibilityExpression} = args;
        const indentRem = (1.6 * (indent ?? 0) + 1.6).toString();
        this.label.style.paddingLeft = `${indentRem}rem`;
        //this.answerItem = mkNode('div', {className: 'answer-item', parent: this.column});
        //this.answerLabel = mkNode('div', {className: 'answer-label', parent: this.answerItem});
        this.visibilityExpression = visibilityExpression;
        //this.answerLabel.appendChild(this.label);
        //frag.appendChild(this.answerItem);
    }

    public setReadOnly(): void {
        // no-op
    }

    /** Load any stored answer */
    public loadAnswer(): Promise<void> {
        return Promise.resolve();
    }

    public loadingComplete(): void {
        super.loadingComplete();
    }

    //public getAnswer(): AnswerKey & AnswerValue {
    //    return {qno: this.qno, ano: this.ano, answer: null};
    //}

    /** Get the answer value */
    public getValue(): string {
        return '';
    }

    /** Set whether this question is visible or hidden */
    //public setVisible(vis: boolean): void {
    //    //this.answerItem.style.display = vis ? 'block' : 'none';
    //    this.context.responses.setVisible(this.qno, this.ano, vis);
    //}

    /** Free the resources used by LongtextQuestion */
    public destroy(): void {
        //removeChildren(this.answerLabel)
        //removeNode(this.answerItem);
        super.destroy();
    }

    public focus(): void {
        scrollRangeIntoView(this.label, this.label);
    }
}

registerAnswerType({
    name: 'Label',
    isThis: (answer: PractiqueNet.ExamJson.Definitions.Answer): boolean => {
        return answer.type.toLowerCase() === 'label';
    },
    makeAnswer: (
        qno: number,
        context: QuestionContext,
        _updateVisibility: () => void,
        question: QuestionManifest,
        answer: PractiqueNet.ExamJson.Definitions.Answer,
        frag: DocumentFragment,
        ano: number,
        lightbox: Lightbox,
        isRemoteShowHide: boolean,
        isOSCE: boolean,
    ) => new QuestionLabel({
        context,
        qno,
        ano,
        backendQid: question.manifest.backend_id,
        backendAid: answer.backend_id,
        showNumber: question.manifest.answers.length > 1,
        label: answer.label,
        frag,
        lightbox,
        isRemoteShowHide,
        indent: answer.indent,
        visibilityExpression: answer.visible,
        resources: question.answersResources[ano],
        mandatory: answer.mandatory,
        type: answer.type.toLowerCase(),
        isOSCE,
    }),
});
