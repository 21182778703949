import { mkNode, scrollRangeIntoView, removeNode, isIndexed } from '@p4b/utils';
import { LocalData } from '@p4b/exam-service';
import { Question, QuestionContext, QuestionManifest, QuestionBase, Expr, registerAnswerType, QuestionArgs } from '@p4b/question-base';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { Lightbox } from '@p4b/lightbox';
import { translate } from '@p4b/utils-lang';
import { configDngrPress, configSafePress } from '@p4b/exam-accessibility';

type Option = null | {value: string, backendId?: string};

function isOption(opt: unknown): opt is {value: string, backendId?: string} {
    return isIndexed(opt) && typeof opt.value === 'string' && (
        typeof opt.backendId === 'undefined' ||
        typeof opt.backendId === 'string'
    );
}

function isExclude(x: unknown): x is {excluded: boolean[]} {
    return isIndexed(x) && x.excluded instanceof Array &&
        x.excluded.reduce((acc, y) => acc && (typeof y === 'boolean'), true);
}

/** Dropdown question textarea UI */
class QuestionSBA extends QuestionBase implements Question {
    private answerItem: HTMLDivElement;
    //private answerLabel: HTMLDivElement;
    private options: HTMLDivElement;

    private answer: Option;
    private excluded: boolean[];
    private updateVisibility: () => void;
    //private disabled: boolean;
    private optionButtons: HTMLButtonElement[]= [];
    private excludeButtons: HTMLButtonElement[] = [];

    public readonly visibilityExpression?: Expr;

    /** Construct Dropdown Question UI */
    public constructor(args: Omit<QuestionArgs, 'showFlag'> & {
        updateVisibility: () => void,
        visibilityExpression?: Expr,
        options: string[],
        optionDetails: PractiqueNet.ExamJson.Definitions.AnswerOptionDetails[],
        optionOrder?: number[],
    }) {
        super({...args, showFlag: true});
        const {indent, options, optionOrder, context, optionDetails, updateVisibility, visibilityExpression} = args;
        const indentRem = String(1.6 * (indent ?? 0) + 1.6);
        this.label.style.paddingLeft = `${indentRem}rem`;
        this.answerItem = mkNode('div', {className: 'answer-item', parent: this.column});
        //this.answerLabel = mkNode('div', {className: 'answer-label', parent: this.answerItem});
        this.options = mkNode('div', {parent: this.answerItem});
        //console.log('ORIG   ORDER', options)
        //console.log('OPTION ORDER', optionOrder)
        for (let j = 0; j < options.length; ++j) {
            const idx = (optionOrder) ? optionOrder[j] : j;
            const row = mkNode('div', {className: 'sba-option-row', parent: this.options});
            const elem = mkNode('button', {className: 'sba-option break-word ' + configSafePress, title: translate('SBA_ANSWER_TIP'), parent: row, tabindex: 0, disabled: true});
            const exclude = mkNode('button', {className: 'sba-option-del '  + configDngrPress, title: translate('SBA_ELIMINATE_TIP'), tabindex: 0, disabled: true, children: [
                mkNode('icon', {icon: faTimes}),
            ]});
            this.excludeButtons[j] = exclude;
            if (!context.meta?.disableAnswerElimination) {
                row.appendChild(exclude);
            }
            elem.innerHTML = '<div>' + options[idx] + '</div>' + ((optionDetails[idx]?.description) ? ('<div class="option-details">' + optionDetails[idx]?.description + '</div>') : '');
            elem.dataset.value = options[idx];
            elem.dataset.backendId = String(optionDetails[idx]?.backend_id);
            this.optionButtons[j] = elem;
        }
        this.updateVisibility = updateVisibility;
        this.visibilityExpression = visibilityExpression;
        //this.answerLabel.appendChild(this.label);
        this.answer = null;
        this.excluded = Array(options.length).fill(false);
        //frag.appendChild(this.answerItem);
    }

    /** Load any stored answer */
    public loadAnswer(response?: LocalData) {
        try {
            console.debug('LOADED', response);
            if (response) {
                console.debug('OPTION', response);
                if (isExclude(response.extra) && (!this.context.meta?.disableAnswerElimination)) {
                    this.excluded = response.extra.excluded;
                }
                if (isOption(response.answer)) {
                    this.answer = response.answer;
                }
            }
            for (let i = 0; i < this.excludeButtons.length; ++i) {
                this.excludeButtons[i].setAttribute('aria-pressed', String(this.excluded[i]));
            }
            for (let i = 0; i < this.optionButtons.length; ++i) {
                const opt = this.optionButtons[i];
                if (this.answer && ((this.answer.backendId)
                    ? (opt.dataset.backendId === this.answer.backendId)
                    : (opt.dataset.value === this.answer.value))
                ) {
                    opt.setAttribute('aria-pressed', 'true');
                } else {
                    opt.setAttribute('aria-pressed', 'false');
                }
            }
            this.updateVisibility();
        } catch(e) {
            console.error(String(e));
        }
    }

    private updateDisabled() {
        for (let i = 0; i < this.excludeButtons.length; ++i) {
            this.optionButtons[i].setAttribute('aria-readonly', String(this.isReadOnly))
            this.optionButtons[i].setAttribute('aria-disabled', String(this.excluded[i]))
            this.optionButtons[i].disabled = this.isDisabled(this.excluded[i]);
        }
        for (let i = 0; i < this.optionButtons.length; ++i) {
            const opt = this.optionButtons[i];
            this.excludeButtons[i].disabled = this.isDisabled(
                (this.answer && (this.answer.backendId
                ? (opt.dataset.backendId === this.answer.backendId)
                : (opt.dataset.value === this.answer.value))) ?? false
            );
        }
    }

    public setReadOnly(isReadOnly: boolean): void {
        super.setReadOnly(isReadOnly);
        this.updateDisabled();
    }

    public loadingComplete(): void {
        super.loadingComplete();
        this.updateDisabled();
        this.options.addEventListener('click', this.clickHandler);
        //this.options.addEventListener('keydown', this.keydownHandler);
    }

    /** Get the answer value */
    public getValue(): string {
        return this.answer?.value ?? '';
    }

    /** Free the resources used by LongtextQuestion */
    public destroy(): void {
        removeNode(this.answerItem);
        this.options.removeEventListener('click', this.clickHandler);
        //this.options.removeEventListener('keydown', this.keydownHandler);
        super.destroy();
    }

    public focus(): void {
        scrollRangeIntoView(this.answerItem, this.answerItem);
    }

    public isDisabled(disable = false): boolean {
        return this.isLoading || this.isReadOnly || disable;
    }

    //public getAnswer(): AnswerKey & AnswerValue {
    //    return {qno: this.qno, ano: this.ano, answer: this.answer, extra: {excluded: this.excluded}};
    //}

    private async submit() {
        try {
            await this.context.responses.saveAnswer({qno: this.qno, ano: this.ano}, {answer: this.answer, extra: {excluded: this.excluded}});
        } catch(e) {
            console.error(String(e));
        } finally {
            this.updateVisibility();
        }
    }

    private select(x: number): void {
        console.debug('SELECT', x);
        for (let i = 0; i < x; ++i) {
            this.optionButtons[i].setAttribute('aria-pressed', 'false');
            this.excludeButtons[i].disabled = this.isDisabled(false);

        }
        const opt = this.optionButtons[x];
        const value = opt.dataset.value;
        const backendId = opt.dataset.backendId;
        if (value && backendId && opt.getAttribute('aria-pressed') === 'false') {
            opt.setAttribute('aria-pressed', 'true');
            this.excludeButtons[x].disabled = this.isDisabled(true);
            this.answer = {value, backendId};
        } else {
            opt.setAttribute('aria-pressed', "false");
            this.answer = null;
            this.excludeButtons[x].disabled = this.isDisabled(false);
        }
        for (let i = x + 1; i < this.optionButtons.length; ++i) {
            this.optionButtons[i].setAttribute('aria-pressed', 'false');
            this.excludeButtons[i].disabled = this.isDisabled(false);
        }
    }

    private exclude(x: number): void {
        console.debug('EXCLUDE', x);
        this.excluded[x] = !this.excluded[x];
        this.optionButtons[x].disabled = this.isDisabled(this.excluded[x]);
        this.excludeButtons[x].setAttribute('aria-pressed', String(this.excluded[x]));
        this.optionButtons[x].setAttribute('aria-disabled', String(this.excluded[x]))
    }

    private async update(node: Node): Promise<void> {
        let submit = false;
        for (let i = 0; i < this.optionButtons.length; ++i) {
            if (this.optionButtons[i].contains(node) && !this.excluded[i]) {
                this.select(i);
                submit = true;
                break;
            }
        }
        for (let i = 0; i < this.excludeButtons.length; ++i) {
            if (this.excludeButtons[i].contains(node) && this.optionButtons[i].getAttribute('aria-pressed') === 'false') {
                this.exclude(i)
                submit = true;
                break
            }
        }
        if (submit) {
            await this.submit();
        }
    }

    //private keydownHandler = async (k: KeyboardEvent): Promise<void> => {
    //    if (!this.isDisabled() && k.target instanceof Node && k.key === 'Enter') {
    //        await this.update(k.target);
    //    }
    //}

    private clickHandler = async (e: MouseEvent): Promise<void> => {
        if (!this.isDisabled() && e.target instanceof Node) {
            this.context.navigation.setNavigating(true);
            await this.update(e.target);
            this.context.navigation.setNavigating(false);
        }
    }
}

registerAnswerType({
    name: 'SBA',
    isThis: (answer: PractiqueNet.ExamJson.Definitions.Answer, n?: number, meta?: PractiqueNet.ExamJson.Definitions.ExamMeta, isOSCE?: boolean): boolean => {
        return answer.type.toLowerCase() === 'sba' && (n === 1 || (!isOSCE && meta?.disableAnswerElimination === false));
    },
    makeAnswer: (
        qno: number,
        context: QuestionContext,
        updateVisibility: () => void,
        question: QuestionManifest,
        answer: PractiqueNet.ExamJson.Definitions.Answer,
        frag: DocumentFragment,
        ano: number,
        lightbox: Lightbox,
        isRemoteShowHide: boolean,
        isOSCE: boolean,
    ) => new QuestionSBA({
        updateVisibility,
        context,
        qno,
        ano,
        backendQid: question.manifest.backend_id,
        backendAid: answer.backend_id,
        showNumber: question.manifest.answers.length > 1,
        label: answer.label,
        frag,
        options: (answer.type === 'SBA' || answer.type === 'dropdown') ? answer.options : [],
        optionDetails: (answer.type === 'SBA' || answer.type === 'dropdown') ? (answer.optionDetails ?? []) : [],
        lightbox,
        isRemoteShowHide,
        indent: answer.indent,
        visibilityExpression: answer.visible,
        optionOrder: (answer.type === 'SBA' || answer.type === 'dropdown') ? answer.candidateToOptionOrder?.[context.candidateId] : [],
        notes: answer.notes,
        resources: question.answersResources[ano],
        mandatory: answer.mandatory,
        type: answer.type.toLowerCase(),
        isOSCE,
    })
});
