import { mkNode, removeChildren, removeNode } from '@p4b/utils';
import { translate } from '@p4b/utils-lang';
import { configErrorPanel, configGhostPrimaryButton, configInfoPanel, configSolidPrimaryButton, configWarnPanel } from './exam-accessibility';


export interface ModalOptions {
    parent?: Node;
    handler: (id: string) => void;
}

export class Modal {
    private overlay: HTMLDivElement;
    private box: HTMLDivElement;
    private panel: HTMLDivElement;
    //private title?: HTMLDivElement;
    //private text?: HTMLDivElement;
    //private info?: HTMLDivElement;
    private buttons: HTMLDivElement;
    private options: { [id: string]: HTMLInputElement | HTMLButtonElement; } = {};
    private handler: (id: string) => void;
    private previousFocus?: Element;

    constructor({parent = document.body, handler}: ModalOptions) {
        this.overlay = mkNode('div', {className: 'logout-background', parent});
        this.box = mkNode('div', {
            className: 'logout-box config-info900-bg config-info900aaa-text config-info900-border', parent: this.overlay, attrib: {
                role: 'dialog',
                'aria-modal': 'true',
            }, children: [
                mkNode('div', {className: 'login-risr-box', children: [
                    mkNode('img', {
                        className: 'login-risr-logo',
                        src: 'risr-assess-on-dark.png',
                        attrib: {
                            draggable: 'false',
                        }
                    })
                ]})
            ]
        });
        this.panel = mkNode('div', {className: 'logout-panel config-user000-alpha90 config-user100aaa-text', parent: this.box});
        this.buttons = mkNode('div', { className: 'logout-buttons', parent: this.panel });
        mkNode('div', {parent: this.overlay, attrib: {tabindex: '0'}});
        this.handler = handler;
        this.buttons.addEventListener('click', this.handleClick);
        this.overlay.addEventListener('keyup', this.handleKeyup);
        this.overlay.addEventListener('keydown', this.handleKeydown);
        this.panel.addEventListener('focusout', this.handleFocusout);
    }

    public destroy(): void {
        this.panel.removeEventListener('focusout', this.handleFocusout);
        this.overlay.removeEventListener('keydown', this.handleKeydown);
        this.overlay.removeEventListener('keyup', this.handleKeyup);
        this.buttons.removeEventListener('click', this.handleClick);
        removeNode(this.overlay);
    }

    public reset(): void {
        removeChildren(this.panel, this.buttons);
        removeChildren(this.buttons);
        this.options = {};
    }

    public addButtons(className: string, buttons: { [id: string]: string; }): void {
        for (const id in buttons) {
            const button = mkNode('button', { className: className, parent: this.buttons, attrib: { id: `button-${id}` } });
            button.innerHTML = buttons[id];
            this.options[id] = button;
            this.buttons.appendChild(button);
        }
    }

    public addInput(className: string, prompts: { [id: string]: string; }): void {
        for (const id in prompts) {
            const label = mkNode('label', {
                className: className, parent: this.buttons, children: [
                    mkNode('text', { text: prompts[id] })
                ]
            });
            const input = mkNode('input', { className: className, parent: label });
            this.options[id] = input;
            this.buttons.appendChild(label);
        }
    }

    public addTitle(html: string): HTMLDivElement {
        const title = mkNode('div', {
            className: 'logout-title', children: [
                mkNode('html', {html})
            ]
        });
        this.panel.insertBefore(title, this.buttons);
        return title;
    }

    public addInfo(html: string): HTMLDivElement {
        const info = mkNode('div', {
            className: `logout-error ${configInfoPanel}`, children: [
                mkNode('html', {html})
            ]
        });
        this.panel.insertBefore(info, this.buttons);
        return info;
    }

    public addWarn(html: string): HTMLDivElement {
        const error = mkNode('div', {
            className: `logout-error ${configWarnPanel}`, children: [
                mkNode('html', {html})
            ]
        });
        this.panel.insertBefore(error, this.buttons);
        return error;
    }

    public addError(html: string): HTMLDivElement {
        const error = mkNode('div', {
            className: `logout-error ${configErrorPanel}`, children: [
                mkNode('html', {html})
            ]
        });
        this.panel.insertBefore(error, this.buttons);
        return error;
    }

    public addText(html: string): HTMLDivElement {
        const text = mkNode('div', {
            className: 'logout-text', children: [
                mkNode('html', {html})
            ]
        });
        this.panel.insertBefore(text, this.buttons);
        return text;
    }

    public disable(disable: boolean, id?: string): void {
        if (id === undefined) {
            for (const ix in this.options) {
                this.options[ix].disabled = disable;
                this.options[ix].blur();
            }
        } else {
            const x = this.options[id];
            if (x) {
                x.disabled = disable;
                x.blur();
            }
        }
    }

    /*
    public titleHtml(html: string): void {
        if (this.title) {
            this.title.innerHTML = html;
        } else {
            this.title = this.addTitle(html);
        }
    }

    public infoHtml(html: string): void {
        if (this.info) {
            this.info.innerHTML = html;
        } else {
            this.info = this.addInfo(html);
        }
    }

    public bodyHtml(html: string): void {
        if (this.text) {
            this.text.innerHTML = html;
        } else {
            this.text = this.addText(html);
        }
    }
    */

    public show(): void {
        this.overlay.style.display = 'block';
        this.box.style.display = 'inline-block';
        this.panel.style.display = 'block';
        this.previousFocus = document.activeElement ?? undefined;
        for (const id in this.options) {
            if (!this.options[id].disabled) {
                this.options[id].focus();
                break;
            }
        }
    }

    public hide({ unlock } = { unlock: true }): void {
        if (unlock) {
            this.overlay.style.display = 'none';
        }
        this.box.style.display = 'none';
        this.panel.style.display = 'none';
        if (this.previousFocus instanceof HTMLElement) {
            this.previousFocus.focus();
        }
    }

    private readonly handleClick = (event: MouseEvent) => {
        if (event.target instanceof HTMLElement) {
            for (const id in this.options) {
                if (this.options[id].contains(event.target)) {
                    this.handler(id);
                }
            }
        }
    };

    private readonly handleKeyup = (event: KeyboardEvent) => {
        //if (event.key === 'Enter' && event.target instanceof HTMLElement) {
        //    for (const id in this.options) {
        //        if (this.options[id].contains(event.target)) {
        //            this.handler(id);
        //        }
        //    }
        //}
        event.preventDefault();
    };

    private readonly handleKeydown = (event: KeyboardEvent) => {
        if (event.target instanceof HTMLElement && this.panel.contains(event.target)) {
            return;
        }
        event.preventDefault();
    };

    // Force focus to remain in modal, if we leave to the left move to rightmost enabled, if we leave to the right move to leftmost enabled.
    private readonly handleFocusout = (event: FocusEvent) => {
        if (event.relatedTarget == null || (event.relatedTarget instanceof Node && !this.panel.contains(event.relatedTarget))) {
            if (event.relatedTarget == null || (event.target instanceof Node && (event.relatedTarget.compareDocumentPosition(event.target) & Node.DOCUMENT_POSITION_PRECEDING))) {
                for (const id of Object.keys(this.options)) {
                    if (!this.options[id].disabled) {
                        this.options[id].focus();
                        break;
                    }
                }
            } else if (event.target instanceof Node && (event.relatedTarget.compareDocumentPosition(event.target) & Node.DOCUMENT_POSITION_FOLLOWING)) {
                for (const id of Object.keys(this.options).reverse()) {
                    if (!this.options[id].disabled) {
                        this.options[id].focus();
                        break;
                    }
                }
            }
        }
    };

    public getValue(id: string): string {
        return this.options[id].value;
    }

    public async getChoice(): Promise<string> {
        return new Promise<string>(resolve => {
            this.overlay.style.display = 'block';
            this.box.style.display = 'inline-block';
            this.panel.style.display = 'block';
            this.buttons.onclick = (event: MouseEvent): void => {
                if (event.target instanceof HTMLElement) {
                    for (const id in this.options) {
                        if (this.options[id].contains(event.target)) {
                            this.overlay.style.display = 'none';
                            this.box.style.display = 'none';
                            this.panel.style.display = 'none';
                            resolve(id);
                        }
                    }
                }
            };
        });
    }
}

export async function alertModal<T>(message: string, handler?: () => Promise<T>): Promise<T | undefined> {
    return new Promise((resolve, reject) => {
        const modal = new Modal({
            handler: async () => {
                if (handler) {
                    try {
                        modal.hide();
                        const result = await handler();
                        resolve(result);
                    } catch (err) {
                        modal.hide();
                        reject(err);
                    }
                } else {
                    modal.hide();
                    resolve(undefined);
                }
            }
        });
        //modal.titleHtml(translate('ALERT_TITLE'));
        modal.addTitle(translate('ALERT_TITLE'));
        modal.addError(message);
        modal.addButtons(`logout-button ${configSolidPrimaryButton}`, { ok: translate('ALERT_OK') });
        modal.show();
    });
}

export async function showModal<T>({message, mode, handler}: {message: string, mode?: 'info'|'error', handler?: () => Promise<T>}): Promise<T | undefined> {
    return new Promise((resolve, reject) => {
        const modal = new Modal({
            handler: async (id: string) => {
                modal.hide();
                switch (id) {
                    case 'ok':
                    case 'retry':
                        if (handler) {
                            try {
                                const result = await handler();
                                resolve(result);
                            } catch (err) {
                                reject(err);
                            }
                        }
                        break;
                    case 'ignore':

                        break;
                }
                resolve(undefined);
            }
        });
        switch (mode) {
            case 'info':
                //modal.titleHtml(translate('INFO_TITLE'));
                modal.addTitle(translate('INFO_TITLE'));
                modal.addInfo(message);
                modal.addButtons(`logout-button ${configSolidPrimaryButton}`, { ok: translate('ALERT_OK') });
                break;
            case 'error':
            default:
                //modal.titleHtml(translate('ALERT_TITLE'));
                modal.addTitle(translate('ALERT_TITLE'));
                modal.addError(message);
                modal.addButtons(`navbutton ${configSolidPrimaryButton}`, { retry: translate('ALERT_RETRY') });
                modal.addButtons(`navbutton ${configGhostPrimaryButton}`, { ignore: translate('ALERT_IGNORE') });
        }
        modal.show();
    });
}

export async function confirmModal(message: string): Promise<boolean> {
    return new Promise(resolve => {
        const modal = new Modal({
            handler: async (id: string) => {
                modal.hide();
                switch (id) {
                    case 'ok':
                        resolve(true);
                        break;
                    case 'cancel':
                    default:
                        resolve(false);
                        break;
                }
            }
        });
        //modal.titleHtml(translate('ALERT_TITLE'));
        modal.addText(message);
        modal.addButtons(`navbutton ${configSolidPrimaryButton}`, { ok: translate('ALERT_OK') });
        modal.addButtons(`navbutton ${configGhostPrimaryButton}`, { cancel: translate('ALERT_CANCEL') });
        modal.show();
    });
}

export async function simpleModal({ message, primary = {}, other = {} }: { message: string, primary?: { [tag: string]: string; }, other?: { [tag: string]: string }; }): Promise<string> {
    return new Promise(resolve => {
        const modal = new Modal({
            handler: async (option: string) => {
                modal.hide();
                resolve(option);
            }
        });
        //modal.titleHtml(translate('ALERT_TITLE'));
        modal.addTitle(translate('ALERT_TITLE'));
        modal.addText(message);
        modal.addButtons(`navbutton ${configSolidPrimaryButton}`, primary);
        modal.addButtons(`navbutton ${configGhostPrimaryButton}`, other);
        modal.show();
    });
}

export async function warnModal({message, title=translate('WARN_TITLE'), primary={ok: translate('ALERT_OK')}, other={}} : {message: string, title?: string, primary?: {[tag: string]: string}, other?: {[tag: string]: string}}): Promise<string> {
    return new Promise(resolve => {
        const modal = new Modal({
            handler: async (option: string) => {
                modal.hide();
                resolve(option);
            }
        });
        //modal.titleHtml(title);
        modal.addTitle(title);
        modal.addWarn(message);
        modal.addButtons(`logout-button ${configSolidPrimaryButton}`, primary);
        modal.addButtons(`navbutton ${configGhostPrimaryButton}`, other);
        modal.show();
    });
}

export async function errorModal({message, title='', primary={}, other={}}: {message: string, title?: string, primary?: {[tag: string]: string}, other?: {[tag: string]: string }}): Promise<string> {
    return new Promise(resolve => {
        const modal = new Modal({
            handler: async (option: string) => {
                modal.hide();
                resolve(option);
            }
        });
        if (title) {
            //modal.titleHtml(title);
            modal.addTitle(title);
        }
        modal.addError(message);
        modal.addButtons(`navbutton ${configSolidPrimaryButton}`, primary);
        modal.addButtons(`navbutton ${configGhostPrimaryButton}`, other);
        modal.show();
    });
}
