import {Progress} from '@p4b/utils-progress';
import {translate} from '@p4b/utils-lang';
import {safeJsonParse} from '@p4b/utils';
import {version} from '@gen/version';


declare global {
    interface Window {
        SafeExamBrowser: {
            version: string;
            security: {
                configKey: string;
                browserExamKey: string;
                updateKeys(callback: () => void): void;
            }
        }
    }
}

const safeExamBrowser = window.SafeExamBrowser != null

//let admin = false;
let loginId = "";
let loginPwd = "";

export function setCredentials(args: {loginId: string; loginPwd: string}): void {
    //admin = false;
    loginId = args.loginId;
    loginPwd = args.loginPwd;
}

export function clearCredentials(): void {
    //admin = false;
    loginId = "";
    loginPwd = "";
}

let uuid = "";

export function setUUID(args: {uuid: string}): void {
    uuid = args.uuid;
}

export class HttpError extends Error {
    constructor(public readonly status: number, message: string) {
        super(message);
        Object.setPrototypeOf(this, new.target.prototype);
    }

    get name() {
        return 'HttpError';
    }
}

export class AbortError extends Error {
    constructor() {
        super('HTTP operation terminated');
        Object.setPrototypeOf(this, new.target.prototype);
    }

    get name() {
        return 'AbortError';
    }
}

export async function requestJson<T>(url: string, obj: T): Promise<{data?: unknown, req_timestamp?: number, rsp_timestamp?: number}> {
    return new Promise(async (succ, fail) => {
        try {
            const xhr = new XMLHttpRequest();
            xhr.open('POST', url, true);
            xhr.onerror = (): void => {
                fail(new Error(translate('ERROR_NETWORK')));
            };
            xhr.ontimeout = (): void => {
                fail(new Error(translate('ERROR_CONNECTION_TIMEOUT')));
            };
            xhr.onload = () => {
                const rsp_timestamp = Date.now();
                if (xhr.status === 200) {
                    if (xhr.responseText) {
                        const data = safeJsonParse(xhr.responseText);
                        succ({data, req_timestamp, rsp_timestamp});
                    } else {
                        succ({data: null, req_timestamp, rsp_timestamp});
                    }
                } else {
                    fail(new HttpError(xhr.status, `${xhr.statusText} "${xhr.responseText}"`));
                }
            };
            xhr.onabort = () => {
                fail(new AbortError());
            };
            xhr.setRequestHeader('Content-Type', 'application/json');
            xhr.setRequestHeader("Cache-Control", "no-cache, no-store, max-age=0");
            if (safeExamBrowser) {
                xhr.setRequestHeader('X-SafeExamBrowser-RequestHash', window.SafeExamBrowser.security.browserExamKey);
                xhr.setRequestHeader('X-SafeExamBrowser-ConfigKeyHash', window.SafeExamBrowser.security.configKey);
            }
            if (loginId && loginPwd) {
                xhr.setRequestHeader('X-Risr-User', loginId);
                xhr.setRequestHeader('X-Risr-Pass', loginPwd);
            }
            if (uuid) {
                xhr.setRequestHeader('X-Risr-Device-Id', uuid);
            }
            xhr.setRequestHeader('X-Risr-Vers', version);
            xhr.responseType = 'text';
            //xhr.timeout = 300000;
            const req_timestamp = Date.now();
            xhr.send(JSON.stringify(obj));
        } catch (err) {
            fail(err);
        }
    });
}

export function getHead(url: string): Promise<string> {
    return new Promise((succ, fail): void => {
        try {
            const xhr = new XMLHttpRequest();
            xhr.open('HEAD', url, true);

            xhr.onerror = (): void => {
                fail(new Error(translate('ERROR_NETWORK')));
            };
            xhr.ontimeout = (): void => {
                fail(new Error(translate('ERROR_CONNECTION_TIMEOUT')));
            };
            xhr.onabort = () => {
                fail(new AbortError());
            };
            xhr.onload = (): void => {
                if (xhr.status === 200) {
                    succ(xhr.getResponseHeader('content-length') || '0');
                } else {
                    fail(new HttpError(xhr.status, `${xhr.statusText} "${xhr.responseText}"`));
                }
            };
            xhr.setRequestHeader("Cache-Control", "no-cache, no-store, max-age=0");
            if (safeExamBrowser) {
                xhr.setRequestHeader('X-SafeExamBrowser-RequestHash', window.SafeExamBrowser.security.browserExamKey);
                xhr.setRequestHeader('X-SafeExamBrowser-ConfigKeyHash', window.SafeExamBrowser.security.configKey);
            }
            if (loginId && loginPwd) {
                xhr.setRequestHeader('X-Risr-User', loginId);
                xhr.setRequestHeader('X-Risr-Pass', loginPwd);
            }
            if (uuid) {
                xhr.setRequestHeader('X-Risr-Device-Id', uuid);
            }
            xhr.setRequestHeader('X-Risr-Vers', version);
            //xhr.timeout = 30000;
            xhr.send(null);
        } catch (err) {
            fail(err);
        }
    });
}

export function getText(url: string): Promise<string> {
    return new Promise((succ, fail): void => {
        try {
            const xhr = new XMLHttpRequest();
            xhr.open('GET', url, true);
            xhr.onerror = (): void => {
                fail(new Error(translate('ERROR_NETWORK')));
            };
            xhr.ontimeout = (): void => {
                fail(new Error(translate('ERROR_CONNECTION_TIMEOUT')));
            };
            xhr.onabort = () => {
                fail(new AbortError());
            };
            xhr.onload = (): void => {
                if (xhr.status === 200) {
                    succ(xhr.responseText);
                } else {
                    fail(new HttpError(xhr.status, `${xhr.statusText} "${xhr.responseText}"`));
                }
            };
            xhr.setRequestHeader("Cache-Control", "no-cache, no-store, max-age=0");
            if (safeExamBrowser) {
                xhr.setRequestHeader('X-SafeExamBrowser-RequestHash', window.SafeExamBrowser.security.browserExamKey);
                xhr.setRequestHeader('X-SafeExamBrowser-ConfigKeyHash', window.SafeExamBrowser.security.configKey);
            }
            if (loginId && loginPwd) {
                xhr.setRequestHeader('X-Risr-User', loginId);
                xhr.setRequestHeader('X-Risr-Pass', loginPwd);
            }
            if (uuid) {
                xhr.setRequestHeader('X-Risr-Device-Id', uuid);
            }
            xhr.setRequestHeader('X-Risr-Vers', version);
            xhr.responseType = 'text';
            //xhr.timeout = 30000;
            xhr.send(null);
        } catch (err) {
            fail(err);
        }
    });
}

export async function getJson(url: string): Promise<unknown> {
    return new Promise((succ, fail): void => {
        try {
            const xhr = new XMLHttpRequest();
            xhr.open('GET', url, true);
            xhr.onerror = (): void => {
                fail(new Error(translate('ERROR_NETWORK')));
            };
            xhr.ontimeout = (): void => {
                fail(new Error(translate('ERROR_CONNECTION_TIMEOUT')));
            };
            xhr.onabort = () => {
                fail(new AbortError());
            };
            xhr.onload = (): void => {
                if (xhr.status === 200) {
                    succ(JSON.parse(xhr.responseText));
                } else {
                    fail(new HttpError(xhr.status, `${xhr.statusText} "${xhr.responseText}"`));
                }
            };
            xhr.setRequestHeader("Cache-Control", "no-cache, no-store, max-age=0");
            if (safeExamBrowser) {
                xhr.setRequestHeader('X-SafeExamBrowser-RequestHash', window.SafeExamBrowser.security.browserExamKey);
                xhr.setRequestHeader('X-SafeExamBrowser-ConfigKeyHash', window.SafeExamBrowser.security.configKey);
            }
            if (loginId && loginPwd) {
                xhr.setRequestHeader('X-Risr-User', loginId);
                xhr.setRequestHeader('X-Risr-Pass', loginPwd);
            }
            if (uuid) {
                xhr.setRequestHeader('X-Risr-Device-Id', uuid);
            }
            xhr.setRequestHeader('X-Risr-Vers', version);
            xhr.responseType = 'text';
            //xhr.timeout = 30000;
            xhr.send(null);
        } catch (err) {
            fail(err);
        }
    });
}

export function httpDelete(url: string): Promise<void> {
    return new Promise((succ, fail): void => {
        try {
            const xhr = new XMLHttpRequest();
            xhr.open('DELETE', url, true);
            xhr.onerror = (): void => {
                fail(new Error(translate('ERROR_NETWORK')));
            };
            xhr.ontimeout = (): void => {
                fail(new Error(translate('ERROR_CONNECTION_TIMEOUT')));
            };
            xhr.onabort = () => {
                fail(new AbortError());
            };
            xhr.onload = (): void => {
                if (xhr.status === 200) {
                    succ();
                } else {
                    fail(new HttpError(xhr.status, `${xhr.statusText} "${xhr.responseText}"`));
                }
            };
            xhr.setRequestHeader("Cache-Control", "no-cache, no-store, max-age=0");
            if (safeExamBrowser) {
                xhr.setRequestHeader('X-SafeExamBrowser-RequestHash', window.SafeExamBrowser.security.browserExamKey);
                xhr.setRequestHeader('X-SafeExamBrowser-ConfigKeyHash', window.SafeExamBrowser.security.configKey);
            }
            if (loginId && loginPwd) {
                xhr.setRequestHeader('X-Risr-User', loginId);
                xhr.setRequestHeader('X-Risr-Pass', loginPwd);
            }
            if (uuid) {
                xhr.setRequestHeader('X-Risr-Device-Id', uuid);
            }
            xhr.setRequestHeader('X-Risr-Vers', version);
            //xhr.timeout = 30000;
            xhr.send(null);
        } catch (err) {
            fail(err);
        }
    });
}

export interface ProgressUi {
    ui: {
        progressBox: HTMLDivElement;
        titleBox: HTMLDivElement;
        subtextBox: HTMLDivElement;
        title: Text;
        subtext: Text;
        spinner: HTMLDivElement;
        textBox: HTMLDivElement;
        text: Text;
    };
    currentSize?: number;
    totalSize?: number;
}

export async function rangeSupported(url: string): Promise<{ranged:boolean,size:number}> {
    return new Promise((succ, fail): void => {
        try {
            const xhr = new XMLHttpRequest();
            xhr.open('HEAD', url, true);
            xhr.onerror = (): void => {
                fail(new Error(translate('ERROR_NETWORK')));
            };
            xhr.ontimeout = (): void => {
                fail(new Error(translate('ERROR_CONNECTION_TIMEOUT')));
            };
            xhr.onabort = () => {
                fail(new AbortError());
            };
            xhr.onload = (): void => {
                if (xhr.status === 206) {
                    console.log('RANGE', xhr.getResponseHeader('content-range'));
                    console.log('MATCH', xhr.getResponseHeader('content-range')?.match(/\/([0-9]*)$/)?.[1]);
                    const ranged = true;
                    const size = parseInt(xhr.getResponseHeader('content-range')?.match(/\/([0-9]*)$/)?.[1] || '0');
                    succ({ranged, size});
                } else if (xhr.status === 200) {
                    const ranged = false;
                    const size = parseInt(xhr.getResponseHeader('content-length') || '0');
                    succ({ranged, size});
                } else {
                    fail(new HttpError(xhr.status, xhr.statusText));
                }
            }
            xhr.setRequestHeader('Range', 'bytes=0-1');
            xhr.setRequestHeader("Cache-Control", "no-cache, no-store, max-age=0");
            if (safeExamBrowser) {
                xhr.setRequestHeader('X-SafeExamBrowser-RequestHash', window.SafeExamBrowser.security.browserExamKey);
                xhr.setRequestHeader('X-SafeExamBrowser-ConfigKeyHash', window.SafeExamBrowser.security.configKey);
            }
            if (loginId && loginPwd) {
                xhr.setRequestHeader('X-Risr-User', loginId);
                xhr.setRequestHeader('X-Risr-Pass', loginPwd);
            }
            if (uuid) {
                xhr.setRequestHeader('X-Risr-Device-Id', uuid);
            }
            xhr.setRequestHeader('X-Risr-Vers', version);
            xhr.responseType = 'arraybuffer';
            //xhr.timeout = 30000;
            xhr.send(null);
        } catch (err) {
            fail(err);
        }
    });
}

export function getArrayBuffer(url: string, progress: Progress, start?: number, end?: number): Promise<ArrayBuffer> {
    return new Promise((succ, fail): void => {
        try {
            const xhr = new XMLHttpRequest();
            xhr.open('GET', url, true);
            xhr.onerror = (): void => {
                fail(new Error(translate('ERROR_NETWORK')));
            };
            xhr.ontimeout = (): void => {
                console.log('CHARS GOT:', xhr.response.byteLength);
                fail(new Error(translate('ERROR_CONNECTION_TIMEOUT')));
            };
            xhr.onabort = () => {
                fail(new AbortError());
            };
            xhr.onprogress = async (e): Promise<void> => {
                if (e.loaded != null) {
                    await progress.setProgress(e.loaded);
                }
            };
            xhr.onload = (): void => {
                if (xhr.status === 200 || xhr.status === 206) {
                    progress.addOffset(xhr.response.byteLength);
                    if (start || end) {
                        console.debug('downloaded exam chunk, start: ', start, ' end: ', start + xhr.response.byteLength);
                    } else {
                        console.debug('DOWNLOADED');
                    }
                    succ(xhr.response);
                } else {
                    fail(new HttpError(xhr.status, xhr.statusText));
                }
            };
            if (start || end) {
                console.debug('range', start, '-', end);
                xhr.setRequestHeader('Range', 'bytes=' + start + '-' + end);
            }
            xhr.setRequestHeader("Cache-Control", "no-cache, no-store, max-age=0");
            if (safeExamBrowser) {
                xhr.setRequestHeader('X-SafeExamBrowser-RequestHash', window.SafeExamBrowser.security.browserExamKey);
                xhr.setRequestHeader('X-SafeExamBrowser-ConfigKeyHash', window.SafeExamBrowser.security.configKey);
            }
            if (loginId && loginPwd) {
                xhr.setRequestHeader('X-Risr-User', loginId);
                xhr.setRequestHeader('X-Risr-Pass', loginPwd);
            }
            if (uuid) {
                xhr.setRequestHeader('X-Risr-Device-Id', uuid);
            }
            xhr.setRequestHeader('X-Risr-Vers', version);
            xhr.responseType = 'arraybuffer';
            //xhr.timeout = 30000;
            xhr.send(null);
        } catch (err) {
            fail(err);
        }
    });
}
