import { createCanvas, CanvasRenderingContext2D as NodeCanvasRenderingContext2D, ImageData } from 'canvas';
import { GlobalWorkerOptions, getDocument, PDFWorker, PDFDocumentProxy, PDFPageProxy, PageViewport } from 'pdfjs-dist-es5';
import { Img, IType, Renderer, registerRendererType } from '@p4b/image-base';
import { Transform } from '@p4b/utils';

GlobalWorkerOptions.workerSrc = '/html5/libs/pdf.worker.min.js';
let worker: PDFWorker|null = null;

function createWorker(): PDFWorker {
    if (!worker) {
        worker = new PDFWorker();
    }
    return worker;
}

/*
declare global {
    interface PromiseConstructor {
        allSettled<T>(promises: Promise<T>[]): Promise<(
            {status: string; value: T} | {status: string; reason: string}
        )[]>;
    }
}

Promise.allSettled = <T>(promises: Promise<T>[]): Promise<({status: string; value: T} | {status: string; reason: string})[]> => Promise.all(promises.map(async (promise) => {
    try {
        const value = await promise;
        return {
            status: 'fulfilled',
            value: value,
        };
    }
    catch (reason) {
        return {
            status: 'rejected',
            reason: reason,
        };
    }
}));
*/

type Pdf = Img;
//export interface Pdf extends Img {}

export type Type = Pdf;

export function makePdf(id: string, data: ArrayBuffer[]): Pdf {
    return {
        id,
        iType: IType.Pdf,
        frames: data.map(buf => ({
            data: buf,
            dataSize: buf.byteLength,
        })),
        frameCount: 1,
    }
}

export function isPdf(x: Img): x is Pdf {
    return x.iType === IType.Pdf;
}

/*
declare module 'pdfjs-dist' {
    export interface PDFRenderParams {
        transform?: number[];
        background?: string;
    }
    //export interface ViewportParameters {
    //    offsetX?: number;
    //    offsetY?: number;
    //}
}
*/

export class PdfRenderer implements Renderer {
    public index: number;
    public readonly img: Pdf;
    private pdf?: PDFDocumentProxy;
    private page?: PDFPageProxy;
    public viewport?: PageViewport;

    public constructor(img: Pdf) {
        this.img = img;
        this.index = 0;
    }

    public async init() {
        const {data} = this.img.frames[this.index];
        if (data) {
            const pdf = await getDocument({
                worker: createWorker(),
                data: new Uint8Array(data.slice(0)),
            }).promise;
            await this.getDimensions(pdf);
        }
    }

    public destroy(): void {
        if (this.pdf) {
            this.pdf.destroy();
        }
        //if (worker) {
        //    worker.destroy()
        //    worker = null;
        //}
    }

    private async getDimensions(pdf: PDFDocumentProxy): Promise<void> {
        this.page = await pdf.getPage(this.index + 1);
        this.viewport = this.page.getViewport({scale: 1});
        this.img.cols = this.viewport.width;
        this.img.rows = this.viewport.height;
        this.img.frameCount = pdf.numPages;
    }

    public async render(): Promise<void> {
        if (this.pdf) {
            this.page = await this.pdf.getPage(this.index + 1);
            this.viewport = this.page.getViewport({scale: 1});
            this.img.cols = this.viewport.width;
            this.img.rows = this.viewport.height;
        }
    }

    public async renderThumbnail(): Promise<ImageData> {
        const {data} = this.img.frames[this.index];
        if (!data) {
            throw 'pdf is not loaded';
        }
        const pdf = await getDocument({
            worker: createWorker(),
            data: new Uint8Array(data.slice(0)),
        }).promise;
        const page = await pdf.getPage(1);
        const vp = page.getViewport({scale: 1});
        this.img.cols = vp.width;
        this.img.rows = vp.height;
        this.img.frameCount = pdf.numPages;
        const cols = 120 * vp.width / vp.height;
        const rows = 120;
        const scale = Math.min(cols / vp.width, rows / vp.height);
        const canvas = createCanvas(cols, rows);
        const cxt = canvas.getContext('2d', { alpha: false });
        if (cxt == null) {
            throw 'visible CONTEXT is null';
        }
        cxt.imageSmoothingEnabled = true;
        //cxt.imageSmoothingQuality = 'high';
        await page.render({
            canvasContext: cxt as unknown as CanvasRenderingContext2D,
            viewport: page.getViewport({scale: scale})
        }).promise;
        pdf.destroy();
        return  cxt.getImageData(0, 0, canvas.width, canvas.height);
    }

    public async animationFrame(context: NodeCanvasRenderingContext2D, t: Transform): Promise<void> {
        if (this.page && this.viewport && this.img.cols && this.img.rows) {
            const canvas = createCanvas(context.canvas.width, context.canvas.height);
            const cxt = canvas.getContext('2d', {alpha: false});
            cxt.fillStyle = 'black';
            cxt.fillRect(0, 0, canvas.width, canvas.height);
            //t = t.multiplyBy(Transform.identity.translateBy((canvas.width - this.viewport.width) / 2.0, (canvas.height - this.viewport.height) / 2.0));
            cxt.setTransform(new DOMMatrix([t.s, t.r, -t.r, t.s, t.tx, t.ty]));
            cxt.fillStyle = 'white';
            cxt.fillRect(0, 0, this.viewport.width, this.viewport.height);
            cxt.imageSmoothingEnabled = true;
            //cxt.imageSmoothingQuality = 'high';
            cxt.resetTransform();
            await this.page.render({
                canvasContext: cxt as unknown as CanvasRenderingContext2D,
                background: 'rgba(0,0,0,0)',
                transform: [t.s, t.r, -t.r, t.s, t.tx, t.ty],
                viewport: this.viewport,
            }).promise;
            context.drawImage(canvas, 0, 0);
        }
    }

    public async load(
        resources: {
            getImageBegin(): Promise<void>;
            getImageFrame(start: number, end: number): Promise<ArrayBuffer|undefined>;
            getImageEnd(): Promise<void>;
        },
        render: () => Promise<void>,
        progress: (p: number) => void,
    ): Promise<void> {
        await resources.getImageBegin();
        for (const frame of this.img.frames) {
            console.debug('DB_OFFSET', frame.dbOffset, frame.dataSize);
            if (frame.dbOffset != undefined) {
                frame.data = await resources.getImageFrame(frame.dbOffset, frame.dbOffset + frame.dataSize);
            }
            if (frame.data) {
                this.pdf = await getDocument({worker: createWorker(), data: new Uint8Array(frame.data.slice(0))}).promise;
                await this.getDimensions(this.pdf);
                await render();
            }
        }
        progress(200.0);
        await resources.getImageEnd();
    }

    public convexMean(): {mean?: number, stddev?: number} {
        return {};
    }
}

export function isPdfRenderer(renderer: Renderer): renderer is PdfRenderer {
    return isPdf(renderer.img);
}

registerRendererType({
    name: 'PdfRenderer',
    hasMime(mime: string): boolean {
        return mime === 'application/pdf';
    },
    async makeImg(id: string, buffer: ArrayBuffer): Promise<Img> {
        return makePdf(id, [buffer]);
    },
    isThis(resource: Img) {
        return resource.iType === IType.Pdf;
    },
    makeRenderer(resource: Pdf): Renderer {
        return new PdfRenderer(resource);
    }
});
