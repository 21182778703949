import { mkNode } from '@p4b/utils';
import { Thumbnail, ThumbnailBase } from '@p4b/thumbnail-base';
import { Img } from '@p4b/image-base';
import { rendererFromImage } from '@p4b/image-base';
import { ImageViewerElement } from '@p4b/image-viewer';


export class ResourceThumbnail extends ThumbnailBase implements Thumbnail {
    private thumb: HTMLCanvasElement;
    private cols: number;
    private rows: number;

    public constructor(image: Img, frag: Node, releaseButton = false) {
        const {rows, cols} = image;
        const thumbCols = (cols != undefined && rows != undefined) ? Math.floor(120 * cols / rows) : 120;
        const thumbRows = 120;
        let thumb;
        super(frag, image, releaseButton, (figure: HTMLDivElement) => {
            thumb = mkNode('canvas', {
                className: 'thumbnail',
                parent: figure,
                style: {visibility: 'hidden', width: thumbCols + 'px', height: thumbRows + 'px'},
                attrib: {title: 'Click on the thumbnail to view and manipulate the image.', draggable: 'false'},
            });
        });
        this.cols = thumbCols;
        this.rows = thumbRows;
        if (!thumb) {
            throw "Thumbnail canvas is null.";
        }
        this.thumb = thumb;
    }

    public async load(thumbnail: ArrayBuffer|null): Promise<void> {
        if (thumbnail) {
            try {
                const cxt = this.thumb.getContext('2d');
                if (cxt != null) {
                    this.thumb.width = 120;
                    this.thumb.height = 120;
                    if (thumbnail) {
                        const uint8clamped = new Uint8ClampedArray(thumbnail);
                        this.thumb.width = uint8clamped.length / 480;
                        const imageData = new ImageData(uint8clamped, this.thumb.width, this.thumb.height);
                        cxt.putImageData(imageData, 0, 0);
                    }
                    this.thumb.style.visibility = 'visible';
                }
            } catch (e) {
                console.error('THUMBNAIL LOAD ERROR', e);
            }
        }
    }

    public async open(makeViewer: () => ImageViewerElement): Promise<ImageViewerElement> {
        const viewer = makeViewer();
        await viewer.setDicom(rendererFromImage(this.image));
        return viewer;
    }

    public destroy(): void {
        super.destroy();
        //console.debug('thumbnail: destroy');
    }
}
